import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

// Create the Data Context
const DataContext = createContext();

// Context provider component
export const DataProvider = ({ children }) => {
  const [clients, setClients] = useState([]);
  const [websites, setWebsites] = useState([]);
  const [users, setUsers] = useState([]);
  const [leads, setLeads] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [sops, setSops] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [officeClosed, setOfficeClosed] = useState([]);
  const [categories, setCategories] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const fetchData = async (postType) => {
    setLoading(true);
    setError(null);
  
    const fetchAllPages = async (url, headers) => {
      const results = [];
      let page = 1;
      let totalPages = 1;
  
      try {
        while (page <= totalPages) {
          const response = await axios.get(`${url}?per_page=100&page=${page}`, { headers });
          results.push(...response.data);
  
          totalPages = parseInt(response.headers['x-wp-totalpages'], 10) || 1;
          page++;
        }
      } catch (error) {
        console.error(`Error fetching from ${url}:`, error);
        throw error;
      }
  
      return results;
    };
  
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const baseUrl = "https://api.crm.sugarprojects.com/wp-json/wp/v2";
  
      // Define all available endpoints and their corresponding state setters
      const endpoints = {
        clients: {
          url: `${baseUrl}/clients`,
          setter: (data) => setClients(data.sort((a, b) => a.title.rendered.localeCompare(b.title.rendered)))
        },
        websites: {
          url: `${baseUrl}/websites`,
          setter: (data) => setWebsites(data.sort((a, b) => a.title.rendered.localeCompare(b.title.rendered)))
        },
        leads: {
          url: `${baseUrl}/leads`,
          setter: setLeads
        },
        jobs: {
          url: `${baseUrl}/jobs`,
          setter: setJobs
        },
        sops: {
          url: `${baseUrl}/sops`,
          setter: setSops
        },
        invoices: {
          url: `${baseUrl}/invoice`,
          setter: setInvoices
        },
        users: {
          url: `${baseUrl}/users`,
          setter: setUsers
        },
        office_closed: {
          url: `${baseUrl}/office_closed`,
          setter: setOfficeClosed
        },
        events: {
          url: `${baseUrl}/events`,
          setter: setEvents
        }
      };
  
      if (postType && endpoints[postType]) {
        // Fetch single post type
        const data = await fetchAllPages(endpoints[postType].url, headers);
        endpoints[postType].setter(data);
      } else {
        // Fetch all post types
        const fetchPromises = Object.entries(endpoints).map(([_, { url }]) => 
          fetchAllPages(url, headers)
        );
  
        const results = await Promise.all(fetchPromises);
  
        // Update state for all post types
        Object.entries(endpoints).forEach(([_, { setter }], index) => {
          setter(results[index]);
        });

        const response = await axios.get(
          'https://api.crm.sugarprojects.com/wp-json/wp/v2/categories',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              post_type: 'sops',
            },
          }
        );

        setCategories(response.data);
      }
  
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setError('Failed to load data');
    } finally {
      setLoading(false);
    }
  };
  
  // Modify the useEffect to add a way to refetch data
  useEffect(() => {
    fetchData();
  }, []); // Initial fetch
  
  // Add a method to manually refresh data with optional post type parameter
  const refreshData = (postType) => {
    fetchData(postType);
  };

  // Function to add a new client
  const addClient = async (clientName) => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.post(
        'https://api.crm.sugarprojects.com/wp-json/wp/v2/clients',
        { title: clientName, status: 'publish' },
        { headers }
      );
      setClients((prevClients) => [...prevClients, response.data]);
      return true;
    } catch (error) {
      console.error("Error adding client:", error);
      setError("Failed to add client");
      return false;
    }
  };


  const addWebsite = async (name, client) => {
    try {
      const token = localStorage.getItem('token');
  
      // Prepare the data for the POST request, including ACF fields
      const requestData = {
        title: name,
        status: 'publish',
        acf: {
          client,  // Assign the client ID as an ACF field
        },
      };
  
      // Step 1: Create the new website and retrieve its ID
      const websiteResponse = await axios.post(
        "https://api.crm.sugarprojects.com/wp-json/wp/v2/websites",
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const newWebsiteId = websiteResponse.data.id; // Get the new website's ID
  
      // Step 2: Prepare default charges
      const defaultCharges = [
        {
          charge: 'Domain',
          details: '',
          start_date: new Date().toISOString().split('T')[0], // Today's date
          end_date: '', // One year from now
          price: 0, // Example price, adjust as needed
          reminder: false
        },
        {
          charge: 'Hosting & SSL',
          details: '',
          start_date: new Date().toISOString().split('T')[0],
          end_date: '',
          price: 0, // Example price, adjust as needed
          reminder: false
        },
        {
          charge: 'Maintenance',
          details: '',
          start_date: new Date().toISOString().split('T')[0],
          end_date: '',
          price: 50, // Example price, adjust as needed
          reminder: false
        },
        {
          charge: 'MS Office 365',
          details: '',
          start_date: new Date().toISOString().split('T')[0],
          end_date: '',
          price: 0, // Example price, adjust as needed
          reminder: false
        }
      ];
  
      // Step 3: Update the website with default charges
      await axios.post(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${newWebsiteId}`,
        {
          acf: {
            client,
            charges: defaultCharges
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // Step 4: Fetch the existing client to retrieve its current websites list
      const clientResponse = await axios.get(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/clients/${client}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // Extract existing websites and add the new website ID
      const existingWebsites = clientResponse.data.acf.websites || [];
      const updatedWebsites = [...existingWebsites, newWebsiteId];
  
      // Step 5: Update the client's websites field to include the new website
      await axios.post(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/clients/${client}`,
        {
          acf: {
            websites: updatedWebsites,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // Update the state with the new website data
      setWebsites((prevWebsites) => [websiteResponse.data, ...prevWebsites]);
  
      return true; // Explicitly return true on success
    } catch (err) {
      console.error('Error adding website:', err);
      setError('Failed to add website');
      return false; // Explicitly return false on failure
    }
  };


  // Function to add a contact to an existing website
  const addContact = async (websiteId, newContact) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      // Fetch the current website data to retrieve existing contacts
      const websiteResponse = await axios.get(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${websiteId}`,
        { headers }
      );

      // Retrieve the current contacts, if any, and append the new contact
      const existingContacts = websiteResponse.data.acf.contacts || [];
      const updatedContacts = [...existingContacts, newContact];

      // Update the website with the new contacts list
      await axios.post(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${websiteId}`,
        {
          acf: {
            contacts: updatedContacts, // Update only the contacts field
          },
        },
        { headers }
      );

      // Update the local websites state with the new contact
      setWebsites((prevWebsites) =>
        prevWebsites.map((website) =>
          website.id === websiteId ? { ...website, acf: { ...website.acf, contacts: updatedContacts } } : website
        )
      );

      return true;
    } catch (error) {
      console.error("Failed to add contact:", error);
      setError("Failed to add contact");
      return false;
    }
  };

  

  const addNote = async (entityId, entityType, noteText) => {
    if (!noteText.trim()) return; // Avoid adding empty notes
  
    const currentDateTime = new Date().toISOString();
    const username = localStorage.getItem("username"); // Retrieve username from localStorage
  
    const updatedNote = {
      note: noteText,
      time: currentDateTime,
      username: username,
      userImage: "/path/to/default/image.png", // Fallback image
    };
  
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
  
      // Determine the URL and data structure based on whether it's a client or website
      console.log(String(websites[0].id), "=", entityId)
      const entity = entityType === 'client'
        ? clients.find(client => client.id === entityId)
        : websites.find(website => String(website.id) === entityId);
  
      if (!entity) {
        console.error(`${entityType} with ID ${entityId} not found`);
        return;
      }
  
      // Update notes for client or website
      const updatedEntity = {
        ...entity,
        acf: {
          ...entity.acf,
          notes: [...(entity.acf?.notes || []), updatedNote], // Append new note
        },
      };
  
      // Make the API request to update the entity with the new note
      const url = `https://api.crm.sugarprojects.com/wp-json/wp/v2/${entityType}s/${entityId}`;
      await axios.put(
        url,
        { acf: { notes: updatedEntity.acf.notes } },
        { headers }
      );
  
      // Update the state for clients or websites
      if (entityType === 'client') {
        setClients((prevClients) =>
          prevClients.map((client) =>
            client.id === entityId ? updatedEntity : client
          )
        );
      } else if (entityType === 'website') {
        setWebsites((prevWebsites) =>
          prevWebsites.map((website) =>
            website.id === entityId ? updatedEntity : website
          )
        );
      }
  
    } catch (error) {
      console.error("Error adding new note:", error);
    }
  };
  

  // Function to delete a client
  const deleteClient = async (clientId) => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      await axios.delete(`https://api.crm.sugarprojects.com/wp-json/wp/v2/clients/${clientId}`, { headers });
      setClients((prevClients) => prevClients.filter(client => client.id !== clientId));
    } catch (error) {
      console.error("Error deleting client:", error);
      setError("Failed to delete client");
    }
  };

  const deleteWebsite = async (websiteId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${websiteId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setWebsites((prevWebsites) => prevWebsites.filter((website) => website.id !== websiteId));
    } catch (err) {
      console.error('Error deleting website:', err);
      setError('Failed to delete website');
    }
  };

  // Utility function to decode HTML entities
  const decodeHtmlEntities = (str) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = str;
    return textArea.value;
  };

  return (
    <DataContext.Provider value={{
      clients,
      websites,
      leads,
      jobs,
      sops,
      loading,
      error,
      invoices,
      users,
      officeClosed,
      categories,
      events,
      decodeHtmlEntities,
      setCategories,
      fetchData,
      addClient,
      addWebsite,
      deleteClient,
      deleteWebsite,
      addNote,
      setError,
      setWebsites,
      addContact,
      setJobs,
      setLeads,
      setSops,
      refreshData,
      setInvoices,
      setUsers,
    }}>
      {children}
    </DataContext.Provider>
  );
};

// Custom hook to use the context
export const useData = () => useContext(DataContext);