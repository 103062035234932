import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { format, startOfWeek, endOfWeek, addDays, subWeeks, addWeeks } from 'date-fns';
import { Trash2, Info } from 'react-feather';
import { Link } from 'react-router-dom';
import { useData } from './DataContext';
import DatePicker from 'react-datepicker'; // Install using npm install react-datepicker
import 'react-datepicker/dist/react-datepicker.css';

const formatTimeToIncrement = (time) => {
  // Convert time to 15-minute increments
  const [hours, minutes] = time.split(':').map(Number);
  const roundedMinutes = Math.round(minutes / 15) * 15 % 60;
  const adjustedHours = hours + Math.floor(minutes / 15 / 4);
  return `${String(adjustedHours).padStart(2, '0')}:${String(roundedMinutes).padStart(2, '0')}`;
};

const timeToMinutes = (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

const TaskTooltip = ({ task, onDelete, onUpdate }) => {
  const [editingDescription, setEditingDescription] = useState(false);
  const [editingTimes, setEditingTimes] = useState(false);
  const [description, setDescription] = useState(task.description);
  const [startTime, setStartTime] = useState(task.startTime);
  const [endTime, setEndTime] = useState(task.endTime);
  const [timeError, setTimeError] = useState('');

  //console.log(task.jobID)

  useEffect(() => {
    // Validate times when they change
    const formattedStartTime = formatTimeToIncrement(startTime);
    const formattedEndTime = formatTimeToIncrement(endTime);
    
    setStartTime(formattedStartTime);
    setEndTime(formattedEndTime);

    if (timeToMinutes(formattedEndTime) <= timeToMinutes(formattedStartTime)) {
      setTimeError('End time must be after start time');
    } else {
      setTimeError('');
    }
  }, [startTime, endTime]);

  const handleDescriptionChange = () => {
    onUpdate({ ...task, description });
    setEditingDescription(false);
  };

  const handleTimesChange = () => {
    if (!timeError) {
      onUpdate({ ...task, startTime, endTime });
      setEditingTimes(false);
    }
  };

  return (
    <div className="absolute z-50 bg-white border shadow-lg rounded-lg p-4 w-64 text-gray-800">
      <div className="flex justify-between items-center mb-2">
        <Link to={`/jobs/${task.jobID}`}><h3 className="font-bold text-lg text-pink-600 hover:text-pink-500">{task.project}</h3></Link>

        <button
          onClick={(e) => {
            e.stopPropagation();
            onDelete(task);
          }}
          className="bg-red-600 hover:bg-red-700 text-white rounded-full p-1 shadow-md transition"
        >
          <Trash2 size={14} />
        </button>
      </div>
      <div className="text-sm mb-2">
        {editingDescription ? (
          <div>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              autoFocus
              className="w-full h-32 p-2 border-0 rounded focus:ring focus:ring-pink-500"
            />
            <button
              className="text-white bg-pink-600 p-1 rounded hover:bg-pink-500 transition"
              onClick={handleDescriptionChange}
            >
              Update
            </button>
          </div>
        ) : (
          <p
            className="italic text-gray-600 cursor-pointer"
            onClick={() => setEditingDescription(true)}
          >
            {description || 'Click to add a description...'}
          </p>
        )}
      </div>
      <div className="text-sm mb-2">
        {editingTimes ? (
          <div>
            <div className="mb-2">
              <label className="block text-gray-600 text-xs mb-1">Start Time</label>
              <input
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                step="900"  // 15-minute increments
                className="w-full p-2 border rounded focus:ring focus:ring-pink-500"
              />
            </div>
            <div className="mb-2">
              <label className="block text-gray-600 text-xs mb-1">End Time</label>
              <input
                type="time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                step="900"  // 15-minute increments
                className="w-full p-2 border rounded focus:ring focus:ring-pink-500"
              />
            </div>
            {timeError && (
              <p className="text-red-500 text-xs mb-2">{timeError}</p>
            )}
            <button
              className={`text-white p-1 rounded transition ${
                timeError 
                  ? 'bg-gray-400 cursor-not-allowed' 
                  : 'bg-pink-600 hover:bg-pink-500'
              }`}
              onClick={handleTimesChange}
              disabled={!!timeError}
            >
              Update
            </button>
          </div>
        ) : (
          <div className="flex justify-between text-sm cursor-pointer" onClick={() => setEditingTimes(true)}>
            <span className="font-medium">Start: {startTime}</span>
            <span className="font-medium">End: {endTime}</span>
          </div>
        )}
      </div>
    </div>
  );
};


const WorkHoursTimesheet = () => {
  const [currentWeek, setCurrentWeek] = useState(new Date());
  const [tasks, setTasks] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [selection, setSelection] = useState(null);
  const [newTask, setNewTask] = useState({
    project: '',
    description: '',
    date: '',
    startTime: '',
    endTime: '',
  });
  const [hoveredTask, setHoveredTask] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());

  const username = localStorage.getItem("username");

  const { jobs, refreshData, websites } = useData();

  const [websiteInput, setWebsiteInput] = useState(""); // State to store the selected website
  const [filteredJobs, setFilteredJobs] = useState(jobs); // State to store filtered jobs
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  const weekDates = Array.from({ length: 7 }, (_, i) =>
    addDays(startOfWeek(currentWeek), i)
  )
    .filter((date) => ![0, 6].includes(date.getDay())) // Exclude Sunday (0) and Saturday (6)
    .map((date) => format(date, 'yyyy-MM-dd'));

  const timeSlots = Array.from({ length: 40 }, (_, i) => {
    const hours = Math.floor(i / 4) + 8; // Start from 8 AM
    const minutes = (i % 4) * 15;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  });

  useEffect(() => {
    setFilteredJobs(jobs)
  }, [jobs]);

  const updateTask = async (updatedTask) => {
    try {
      refreshData("users");
      refreshData("jobs");
      const job = jobs.find((job) => job?.title?.rendered === updatedTask.project);
      if (!job) {
        alert('Job not found');
        return;
      }
  
      const token = localStorage.getItem('token');
  
      // Update the specific log by index
      const updatedLogs = job.acf.logs.map((log, index) =>
        index === updatedTask.logIndex
          ? {
              ...log,
              details: updatedTask.description,
              start_time: updatedTask.startTime,
              end_time: updatedTask.endTime,
            }
          : log
      );
  
      await axios.post(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/jobs/${job.id}`,
        {
          acf: {
            logs: updatedLogs,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // Fetch user by slug
      const userResponse = await axios.get(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/users?slug=${username}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const user = userResponse.data[0]; // Assuming slug is unique
  
      if (!user) {
        alert('User not found');
        return;
      }
  
      const updatedUserLogs = user.acf.logs.map((log) =>
        log.date === updatedTask.date && log.start_time === job.acf.logs[updatedTask.logIndex].start_time
          ? {
              ...log,
              details: updatedTask.description,
              start_time: updatedTask.startTime,
              end_time: updatedTask.endTime,
            }
          : log
      );

      console.log(updatedUserLogs);
  
      // Update user logs
      await axios.post(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/users/${user.id}`,
        {
          acf: {
            logs: updatedUserLogs,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === updatedTask.id ? updatedTask : task
        )
      );
      refreshData("users");
      refreshData("jobs");
    } catch (error) {
      console.error('Error updating task:', error);
      alert('Failed to update the task. Please try again.');
    }
  };


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date()); // Update the current time every minute
    }, 60000); // 60 seconds interval

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const getCurrentLinePosition = () => {
    const now = currentTime;
    const currentDate = format(now, 'yyyy-MM-dd');
    const dayIndex = weekDates.indexOf(currentDate);

    if (dayIndex === -1) return null; // Return null if the current day isn't in the week

    const hours = now.getHours();
    const minutes = now.getMinutes();
    const totalMinutesSinceStart = (hours - 8) * 60 + minutes; // Assuming times start at 8:00 AM
    const position = (totalMinutesSinceStart / 15) * 15; // Convert minutes to pixels (15px per 15-minute slot)

    if (totalMinutesSinceStart < 0 || totalMinutesSinceStart > timeSlots.length * 15) {
      return null; // Return null if outside the bounds of the timesheet
    }

    return { dayIndex, position };
  };
  

  

  useEffect(() => {
    const logs = jobs
      .flatMap((job) =>
        (job.acf?.logs || []).map((log, index) => ({
          ...log,
          project: job.title?.rendered || 'Untitled', // Fallback for title
          colour: job.acf?.colour || '#00bcd4', // Default color
          jobID: job.id, // Include the job ID
          logIndex: index, // Add the index of the log
        }))
      )
      .filter((log) => log.name === username);
  
    const formattedTasks = logs.map((log) => ({
      id: `${log.jobID}-${log.logIndex}`, // Use jobID and logIndex for a unique identifier
      project: log.project,
      description: log.details,
      date: format(new Date(log.date), 'yyyy-MM-dd'),
      startTime: log.start_time,
      endTime: log.end_time,
      colour: log.colour,
      logIndex: log.logIndex, // Include the index in the task object
      jobID: log.jobID, // Include the job ID for reference
    }));
  
    setTasks(formattedTasks);
  }, [jobs, username]);
  
  


  const handleWeekChange = (direction) => {
    setCurrentWeek((prev) => (direction === 'prev' ? subWeeks(prev, 1) : addWeeks(prev, 1)));
  };

  const handleDateChange = (date) => {
    setCurrentWeek(startOfWeek(date));
  };

  const handleMouseDown = (date, time) => {
    setDragging(true);
    setSelection({ date, originTime: time, startTime: time, endTime: time });
  };

  const handleMouseEnter = (time) => {
    if (dragging && selection) {
      const { originTime } = selection;

      if (time < originTime) {
        setSelection((prev) => ({
          ...prev,
          startTime: time,
          endTime: originTime,
        }));
      } else {
        setSelection((prev) => ({
          ...prev,
          startTime: originTime,
          endTime: time,
        }));
      }
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
    if (selection) {
      const { date, startTime, endTime } = selection;
      const endIndex = timeSlots.indexOf(endTime);
      const adjustedEndTime = timeSlots[endIndex + 1] || endTime;

      setNewTask({ ...newTask, date, startTime, endTime: adjustedEndTime });
    }
  };

  const addTask = async () => {
    refreshData("users");
    refreshData("jobs");
    if (!newTask.project || !newTask.startTime || !newTask.endTime) {
      alert('Please fill in all required fields');
      return;
    }
  
    const selectedJob = jobs.find((job) => job?.title?.rendered === newTask.project);
  
    if (!selectedJob) {
      alert('Project not found');
      return;
    }

  
    var taskToAdd = {
      date: newTask.date,
      start_time: newTask.startTime,
      end_time: newTask.endTime,
      details: newTask.description,
      name: username,
      //job_id: selectedJob.id,
    };

    console.log(taskToAdd)
  
    const token = localStorage.getItem('token');
  
    try {
      // Step 1: Fetch the existing logs for the job
      const jobResponse = await axios.get(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/jobs/${selectedJob.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const existingLogs = jobResponse.data.acf.logs || [];
      const updatedLogs = [...existingLogs, taskToAdd];
  
      // Step 2: Update the job logs
      await axios.post(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/jobs/${selectedJob.id}`,
        {
          acf: {
            logs: updatedLogs,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // Step 3: Fetch the user based on the slug (username)
      const userResponse = await axios.get(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/users?slug=${username}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const user = userResponse.data[0]; // Assuming slug is unique
      console.log(user)
  
      if (!user) {
        alert('User not found');
        return;
      }

      var taskToAdd2 = {
          date: newTask.date,
          start_time: newTask.startTime,
          end_time: newTask.endTime,
          details: newTask.description,
          job_id: String(selectedJob.id),
      };
      console.log(taskToAdd2);

      const userLogs = user.acf.logs || [];
      const updatedUserLogs = [...userLogs, taskToAdd2];
      console.log(updatedUserLogs);
  
      // Step 4: Update the user logs
      await axios.post(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/users/${user.id}`,
        {
          acf: {
            logs: updatedUserLogs,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // Update frontend state
      const newLogIndex = updatedLogs.length - 1;
      setTasks([
        ...tasks,
        {
          ...newTask,
          id: `${selectedJob.id}-${newLogIndex}`,
          logIndex: newLogIndex,
          colour: selectedJob?.acf?.colour,
        },
      ]);
  
      setNewTask({
        project: '',
        description: '',
        date: '',
        startTime: '',
        endTime: '',
      });
      setSelection(null);
      refreshData("users");
      refreshData("jobs");
    } catch (error) {
      console.error('Error adding task to backend:', error);
      alert('Failed to add the task. Please try again.');
    }
  };
  
  
  
  
  
  const deleteTask = async (taskToDelete) => {
    const token = localStorage.getItem('token');
    refreshData("users");
    refreshData("jobs");

  
    try {
      // Find the job that contains the log
      const job = jobs.find((job) => job?.title?.rendered === taskToDelete.project);
      //console.log(job);
  
      if (!job) {
        alert('Job not found');
        return;
      }
  
      const updatedLogs = job.acf.logs.map((log, index) =>
        index === taskToDelete.logIndex
          ? null  // Remove the log by returning null
          : log
      ).filter(log => log !== null);  // Filter out the null values to keep only the remaining logs
      
      // Update the job in the backend
      await axios.post(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/jobs/${job.id}`,
        {
          acf: {
            logs: updatedLogs,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // Fetch user by slug
      const userResponse = await axios.get(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/users?slug=${username}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const user = userResponse.data[0]; // Assuming slug is unique
  
      if (!user) {
        alert('User not found');
        return;
      }
  
      const updatedUserLogs = user.acf.logs
        .map((log) =>
          log.date === taskToDelete.date && log.start_time === taskToDelete.startTime
            ? null
            : log
        )
        .filter((log) => log !== null);  // Filter out the null values
  
      // Update user logs
      await axios.post(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/users/${user.id}`,
        {
          acf: {
            logs: updatedUserLogs,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // Update local state
      setTasks(tasks.filter((task) => task.id !== taskToDelete.id));
      
      // Refresh data to ensure sync with backend
      refreshData("users");
      refreshData("jobs");
  
    } catch (error) {
      console.error('Error deleting task from backend:', error);
      alert('Failed to delete the task. Please try again.');
    }
  };
  

  // Add useEffect to reset job selection if current project is no longer in filtered jobs
  useEffect(() => {
    // Check if the current project is in the filtered jobs
    const isCurrentProjectStillAvailable = filteredJobs.some(
      (job) => job?.title?.rendered === newTask.project
    );

    // If not, reset the project
    if (newTask.project && !isCurrentProjectStillAvailable) {
      setNewTask(prev => ({
        ...prev,
        project: '' // Reset to default
      }));
    }
  }, [filteredJobs, newTask.project]);

  const handleWebsiteInputChange = (e) => {
    const inputValue = e.target.value;
    setWebsiteInput(inputValue);
  
    // If input is empty, show all jobs
    if (!inputValue) {
      setFilteredJobs(jobs);
      return;
    }
  
    // Normalize the input value and website titles to handle case-insensitivity and excess whitespace
    const normalizedInput = inputValue.trim().toLowerCase();
  
    // Filter jobs by website name (case-insensitive)
    const filtered = jobs.filter((job) => {
      // Try to find a matching website (normalize both the title and input)
      const matchingWebsite = websites.find(
        (website) => 
          website?.title?.rendered
            .trim()  // Remove leading/trailing whitespace
            .toLowerCase()  // Make the comparison case-insensitive
            .includes(normalizedInput)
      );
  
      // If a matching website is found, check if the job is associated with it
      if (matchingWebsite) {
        const jobWebsite = job?.acf?.website;
  
        // Handle both single value and array
        if (Array.isArray(jobWebsite)) {
          return jobWebsite.map(String).includes(String(matchingWebsite.id));
        } else {
          return String(jobWebsite) === String(matchingWebsite.id);
        }
      }
  
      return false;
    });
  
    setFilteredJobs(filtered);
  };
  

  const handleWebsiteSelectFromDropdown = (website) => {
    // Update the input field with the selected website's title
    const websiteTitle = website?.title?.rendered || "";
    setWebsiteInput(websiteTitle);
  
    // Create a synthetic event to mimic an input change
    const syntheticEvent = { target: { value: websiteTitle } };
  
    // Call handleWebsiteInputChange to reuse its filtering logic
    handleWebsiteInputChange(syntheticEvent);
  
    setIsFocused(false); // Close the dropdown after selection
  
    // Optionally focus back on the input
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  
  
  const filteredWebsites = websites.filter((website) => 
    website?.title?.rendered
      .toLowerCase()
      .startsWith(websiteInput.toLowerCase())  // Use startsWith for strict start matching
  );

  const renderTasksForDate = (date) => {
    const dayTasks = tasks.filter((task) => task.date === date);

    return dayTasks.map((task) => {
      const startIndex = timeSlots.indexOf(task.startTime);
      const endIndex = timeSlots.indexOf(task.endTime);
      const top = startIndex * 15;
      const height = (endIndex - startIndex) * 15;

      // Determine if the task is too small to show full content
      const isSmallTask = height <= 45; // Adjust this threshold as needed

      return (
        <div
          key={task.id}
          className="absolute w-full text-white text-xs group"
          style={{
            top: `${top}px`,
            height: `${height}px`,
            backgroundColor: `${task.colour}80`,
          }}
          onMouseEnter={() => setHoveredTask(task)}
          onMouseLeave={() => setHoveredTask(null)}
        >
          <div className="flex h-full">
            {/* Left Border */}
            <div
              className="flex-shrink-0"
              style={{
                width: '5px', // Thickness of the border
                backgroundColor: task.colour, // Full-color for the border
              }}
            ></div>

            {/* Content */}
            <div className="flex-grow pl-1 overflow-hidden ">
              {!isSmallTask ? (
                <>
                  <div className="font-bold text-sm truncate">{task.project}</div>
                  <div className="italic text-sm text-white truncate">{task.description}</div>
                  <div className="text-sm">
                    {task.startTime} - {task.endTime}
                  </div>
                </>
              ) : (
                <div className="flex items-center justify-center h-full">
                  <Info size={16} className="text-white opacity-70" />
                </div>
              )}
            </div>
          </div>

          {/* Tooltip */}
          {hoveredTask === task && (
            <TaskTooltip 
              task={task} 
              onDelete={deleteTask}
              onUpdate={updateTask}
            />
          )}
        </div>
      );
    });
  };

  const renderTimeBlocks = (date) => {
    const currentLine = getCurrentLinePosition();
    const isToday = weekDates[currentLine?.dayIndex] === date;

    return (
      <div className="relative h-[600px]">
        {isToday && (
          <div
            className="absolute w-full h-[1px] bg-pink-500 z-10"
            style={{ top: `${currentLine.position}px` }}
          ></div>
        )}
        {timeSlots.map((time, index) => {
          const isSelected =
            selection &&
            selection.date === date &&
            time >= selection.startTime &&
            time <= selection.endTime;

          const isHourStart = time.endsWith(':00');

          return (
            <div key={time} className="relative">
              <div
                className={`absolute w-full border-b h-[15px] ${
                  isSelected ? 'bg-pink-300' : 'bg-gray-100 hover:bg-gray-200'
                }`}
                style={{ top: `${index * 15}px` }}
                onMouseDown={() => handleMouseDown(date, time)}
                onMouseEnter={() => handleMouseEnter(time)}
                onMouseUp={handleMouseUp}
              ></div>

              {isHourStart && (
                <div
                  className="absolute w-full border-t border-gray-400"
                  style={{ top: `${index * 15}px` }}
                ></div>
              )}
            </div>
          );
        })}
        {renderTasksForDate(date)}
      </div>
    );
  };
  
  
    return (
        <div className="mx-auto p-6 bg-white shadow-md rounded-lg">
            <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">Work Hours Timesheet</h1>
                <div className="flex items-center space-x-4">
                    <button
                        onClick={() => handleWeekChange('prev')}
                        className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded"
                    >
                        ← Previous Week
                    </button>
                    <DatePicker
                        selected={currentWeek}
                        onChange={handleDateChange}
                        dateFormat="MMM dd, yyyy"
                        className="border-0 rounded p-2 ring-1 ring-gray-300 focus:ring-pink-600"
                    />
                    <button
                        onClick={() => handleWeekChange('next')}
                        className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded"
                    >
                        Next Week →
                    </button>
                </div>
            </div>

            <div className="text-sm text-gray-600 mb-4">
                Week of: {format(startOfWeek(currentWeek), 'MMM dd, yyyy')} -{' '}
                {format(endOfWeek(currentWeek), 'MMM dd, yyyy')}
            </div>

            <div className="grid grid-cols-6 gap-4 mb-6">
                {/* Replace website select with text input */}
                <div className="relative">
                  <input
                    ref={inputRef}
                    type="text"
                    placeholder="Filter by Website"
                    value={websiteInput}
                    onChange={handleWebsiteInputChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setTimeout(() => setIsFocused(false), 200)} // Slight delay to handle click events
                    className="w-full border-0 rounded p-2 ring-1 ring-gray-300 focus:ring-pink-600"
                  />
                  {isFocused && websiteInput && filteredWebsites.length > 0 && (
                    <div className="absolute z-10 w-full bg-white border rounded shadow-lg mt-1 max-h-40 overflow-y-auto">
                      {filteredWebsites.map((website) => (
                        <div 
                          key={website.id}
                          className="p-2 hover:bg-gray-100 cursor-pointer"
                          onMouseDown={(e) => {
                            e.preventDefault(); // Prevent onBlur from firing before click
                            handleWebsiteSelectFromDropdown(website);
                          }}
                        >
                          {website?.title?.rendered}
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                {/* Jobs Select */}
                <select
                  className="border-0 rounded p-2 ring-1 ring-gray-300 focus:ring-pink-600"
                  value={newTask.project}
                  onChange={(e) => setNewTask({ ...newTask, project: e.target.value })}
                >
                  <option value="" disabled>
                    Select Job
                  </option>
                  {filteredJobs.map((job) => (
                    <option key={job.id} value={job?.title?.rendered}>
                      {job?.title?.rendered}
                    </option>
                  ))}
                </select>
                <input
                    className="border-0 rounded p-2 col-span-3 ring-1 ring-gray-300 focus:ring-pink-600"
                    placeholder="Description"
                    value={newTask.description}
                    onChange={(e) =>
                        setNewTask({ ...newTask, description: e.target.value })
                    }
                />
                <button
                    onClick={addTask}
                    className="bg-pink-600 text-white rounded p-2 hover:bg-pink-500 transition"
                >
                    Add Task
                </button>
            </div>

            <div className="w-full overflow-x-auto">
                <table className="w-full border-collapse">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="p-2 border w-[5%]">Time</th>
                            {weekDates.map((date) => (
                                <th key={date} className="p-2 border w-[19%]">
                                    {format(new Date(date), 'EEE dd/MM')}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="p-2 border">
                                {timeSlots.map((time, index) => (
                                    <div
                                        key={time}
                                        className="text-xl text-gray-500 text-right w-full"
                                        style={{ height: '15px' }}
                                    >
                                        {index % 4 === 0 && time}
                                    </div>
                                ))}
                            </td>
                            {weekDates.map((date) => (
                                <td key={date} className="p-2 border relative">
                                    {renderTimeBlocks(date)}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default WorkHoursTimesheet;