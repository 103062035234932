import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip as ChartTooltip, Title, ArcElement, Legend } from 'chart.js';
import { Link } from 'react-router-dom';
import { useData } from './DataContext';
import { ChevronLeft, ChevronRight, ArrowLeft, ChevronDown, ChevronUp } from 'lucide-react';
import axios from 'axios';

ChartJS.register(BarElement, CategoryScale, LinearScale, ChartTooltip, Title, ArcElement, Legend);


const UserTimeLog = () => {
    const { users, jobs, refreshData } = useData();
    const [selectedUser, setSelectedUser] = useState(null);
    const [currentWeek, setCurrentWeek] = useState(0);
    const [expandedCards, setExpandedCards] = useState({});
    const [selectedJobs, setSelectedJobs] = useState({});

    const handleJobSelect = (userId, e) => {
        setSelectedJobs({
            ...selectedJobs,
            [userId]: e.target.value
        });
    };

    const handleAddJobClick = (userId) => {
        const jobId = selectedJobs[userId];
        if (!jobId) {
            alert("Please select a job first");
            return;
        }
        handleAddJob(userId, jobId);
        setSelectedJobs({
            ...selectedJobs,
            [userId]: '' // Reset selection after adding
        });
    };

    const getAvailableJobs = (user) => {
        const userJobIds = new Set(user.acf?.jobs?.map(id => parseInt(id, 10)) || []);
        return jobs.filter(job => !userJobIds.has(job.id));
    };

    const toggleCard = (userName) => {
        setExpandedCards(prev => ({
            ...prev,
            [userName]: !prev[userName]
        }));
    };

    const getUserJobs = (user) => {
        if (!Array.isArray(user.acf.jobs)) return [];
        return user.acf.jobs
            .map(jobId => jobs.find(j => j.id == jobId))
            .filter(Boolean)
            .map(job => ({
                id: job.id,
                name: job.title?.rendered || 'Unknown'
            }));
    };

    const handleRemoveJob = async (userId, jobId) => {
        try {
            refreshData("users");
            const token = localStorage.getItem('token');
            const user = users.find(u => u.id === userId);
            const newJobs = (user.acf?.jobs || []).filter(id => id !== jobId);
            
            await axios.post(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/users/${userId}`,
                {
                    acf: {
                        jobs: newJobs.length ? newJobs : [""]
                    }
                },
                { 
                    headers: { 
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            refreshData("users");
        } catch (err) {
            console.error("Error removing job:", err);
            alert("Failed to remove job");
        }
    };

    const handleAddJob = async (userId, jobId) => {
        try {
            refreshData("users");
            const token = localStorage.getItem('token');
            const user = users.find(u => u.id === userId);
            const newJobs = [...(user.acf?.jobs || []), parseInt(jobId, 10)];
    
            await axios.post(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/users/${userId}`,
                {
                    acf: {
                        jobs: newJobs,
                    },
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
    
            //alert("Job successfully added to user.");
            refreshData("users");
        } catch (err) {
            console.error("Error adding job:", err);
            alert("Failed to add job");
        }
    };
    

    const getWeekDays = (offset = 0) => {
        const now = new Date();
        now.setDate(now.getDate() - now.getDay() + 1 + offset * 7);
        return Array.from({ length: 7 }).map((_, i) => {
            const date = new Date(now);
            date.setDate(date.getDate() + i);
            return date.toISOString().split('T')[0];
        });
    };

    const formatWeekRange = (offset = 0) => {
        const weekDays = getWeekDays(offset);
        const startDate = new Date(weekDays[0]);
        const endDate = new Date(weekDays[6]);
        const formatDate = (date) => {
            return date.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: startDate.getFullYear() !== new Date().getFullYear() ? 'numeric' : undefined
            });
        };
        return `${formatDate(startDate)} - ${formatDate(endDate)}`;
    };

    const dayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const processLogs = (user) => {
        const weekDays = getWeekDays(currentWeek);
        return weekDays.map((day, index) => {
            const logs = Array.isArray(user.acf.logs) ? user.acf.logs.filter((log) => log.date === day) : [];
            const totalHours = logs.reduce((sum, log) => {
                const start = new Date(`1970-01-01T${log.start_time}`);
                const end = new Date(`1970-01-01T${log.end_time}`);
                return sum + (end - start) / (1000 * 60 * 60);
            }, 0);

            const jobSegments = Object.values(
                logs.reduce((acc, log) => {
                    const job = jobs.find((j) => j.id == log.job_id) || {};
                    const start = new Date(`1970-01-01T${log.start_time}`);
                    const end = new Date(`1970-01-01T${log.end_time}`);
                    const hours = (end - start) / (1000 * 60 * 60);
                    const jobName = job.title?.rendered || 'Unknown';
                    if (!acc[jobName]) {
                        acc[jobName] = {
                            id: job.id,
                            name: jobName,
                            color: job.acf?.colour || '#999999',
                            hours: 0,
                            logs: [],
                        };
                    }
                    acc[jobName].hours += hours;
                    acc[jobName].logs.push({
                        start: log.start_time,
                        end: log.end_time,
                        hours,
                        notes: log.notes || ''
                    });
                    return acc;
                }, {})
            );

            return {
                day: dayNames[index],
                date: day,
                totalHours,
                jobSegments,
            };
        });
    };

    const dailyData = users.map((user) => {
        const today = new Date().toISOString().split('T')[0];
        const logs = Array.isArray(user.acf.logs) ? user.acf.logs.filter((log) => log.date === today) : [];
        const totalHours = logs.reduce((sum, log) => {
            const start = new Date(`1970-01-01T${log.start_time}`);
            const end = new Date(`1970-01-01T${log.end_time}`);
            return sum + (end - start) / (1000 * 60 * 60);
        }, 0);

        const jobBreakdown = logs.reduce((acc, log) => {
            const job = jobs.find((j) => j.id == log.job_id) || {};
            const start = new Date(`1970-01-01T${log.start_time}`);
            const end = new Date(`1970-01-01T${log.end_time}`);
            const hours = (end - start) / (1000 * 60 * 60);
            const jobName = job.title?.rendered || 'Unknown';
            if (!acc[jobName]) acc[jobName] = { name: jobName, color: job.acf?.colour || '#0a54e7', value: 0 };
            acc[jobName].value += hours;
            return acc;
        }, {});

        return {
            name: user.name,
            hours: totalHours,
            jobBreakdown: Object.values(jobBreakdown),
        };
    });

    const weeklyData = selectedUser ? processLogs(selectedUser) : [];

    return (
        <div className="p-6 h-[500px]">
            {!selectedUser && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {dailyData.map((userData) => {
                        const user = users.find(u => u.name === userData.name);
                        const userJobs = getUserJobs(user);
                        const isExpanded = expandedCards[userData.name];
                        const availableJobs = getAvailableJobs(user);

                        return (
                            <div key={userData.name} className="p-4 border rounded shadow flex flex-col justify-between">
                                <div>
                                    <div className="flex justify-between items-center mb-2">
                                        <h3 className="text-lg font-bold">{userData.name}</h3>
                                        <button
                                            onClick={() => toggleCard(userData.name)}
                                            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
                                        >
                                            {isExpanded ? (
                                                <ChevronUp className="w-5 h-5" />
                                            ) : (
                                                <ChevronDown className="w-5 h-5" />
                                            )}
                                        </button>
                                    </div>
                                    <p>Hours Today: {userData.hours}</p>
                                    
                                    {isExpanded && userJobs.length > 0 && (
                                        <div className="my-2 p-2 bg-gray-50 rounded">
                                            <div className='flex justify-between'>
                                                <p className="text-sm font-medium mb-1">Assigned Jobs:</p>
                                                <div>
                                                    <select
                                                        value={selectedJobs[user.id] || ''}
                                                        onChange={(e) => handleJobSelect(user.id, e)}
                                                        className="text-sm border rounded py-1 mr-4 focus:ring-pink-600 focus:border-pink-600"
                                                    >
                                                        <option value="">Select a Job</option>
                                                        {availableJobs.map(job => (
                                                            <option key={job.id} value={job.id}>
                                                                {job.title?.rendered || 'Untitled Job'}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <button className='text-sm text-pink-500 hover:text-pink-600' onClick={() => handleAddJobClick(user.id)}>Add Job</button>
                                                </div>
                                            </div>
                                            <ul className="space-y-1">
                                                {userJobs.map(job => (
                                                    <li key={job.id}>
                                                        <div className='flex gap-2 ml-4'>
                                                            <button className='text-sm text-gray-500 hover:text-gray-600' onClick={() => handleRemoveJob(user.id, job.id)}>-</button>
                                                            <Link to={`/jobs/${job.id}`}><h3 className="text-sm text-pink-600 hover:text-pink-500">{job.name}</h3></Link>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}

                                    <div className="h-48">
                                        <Pie
                                            data={{
                                                labels: userData.jobBreakdown.map((job) => job.name),
                                                datasets: [
                                                    {
                                                        data: userData.jobBreakdown.map((job) => job.value),
                                                        backgroundColor: userData.jobBreakdown.map((job) => job.color),
                                                    },
                                                ],
                                            }}
                                            options={{
                                                maintainAspectRatio: false,
                                                plugins: {
                                                    legend: {
                                                        position: 'top',
                                                    },
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                                <button
                                    className="mt-4 px-4 py-2 bg-pink-500 text-white rounded hover:bg-pink-600 transition-colors"
                                    onClick={() => setSelectedUser(users.find((u) => u.name === userData.name))}
                                >
                                    Week View
                                </button>
                            </div>
                        );
                    })}
                </div>
            )}

            {selectedUser && (
                <>
                    <div className="mb-6">
                        <div className="flex items-center justify-between">
                            <button
                                onClick={() => setSelectedUser(null)}
                                className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:text-gray-900 transition-colors"
                            >
                                <ArrowLeft className="w-5 h-5" />
                                <span>Back to Overview</span>
                            </button>
                            <div className="flex items-center gap-4">
                                <button
                                    onClick={() => setCurrentWeek((prev) => prev - 1)}
                                    className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                                >
                                    <ChevronLeft className="w-5 h-5" />
                                </button>
                                <div className="min-w-[200px] text-center font-medium">
                                    {formatWeekRange(currentWeek)}
                                </div>
                                <button
                                    onClick={() => setCurrentWeek((prev) => prev + 1)}
                                    className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                                >
                                    <ChevronRight className="w-5 h-5" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="h-[400px]">
                        <Bar
                            data={{
                                labels: weeklyData.map((data) => data.day),
                                datasets: weeklyData
                                    .flatMap((data) =>
                                        data.jobSegments.map((segment) => ({
                                            label: segment.name,
                                            data: weeklyData.map((d) =>
                                                d.jobSegments.find((s) => s.name === segment.name)?.hours || 0
                                            ),
                                            backgroundColor: segment.color,
                                            stack: 'stack1',
                                            jobId: segment.id,
                                            logs: weeklyData.map((d) => 
                                                d.jobSegments.find((s) => s.name === segment.name)?.logs || []
                                            )
                                        }))
                                    )
                                    .reduce((acc, curr) => {
                                        if (!acc.find((dataset) => dataset.label === curr.label)) {
                                            acc.push(curr);
                                        }
                                        return acc;
                                    }, []),
                            }}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        position: 'top',
                                    },
                                    tooltip: {
                                        mode: 'point',
                                        callbacks: {
                                            title: (context) => {
                                                const dataIndex = context[0].dataIndex;
                                                const datasetIndex = context[0].datasetIndex;
                                                const logs = context[0].dataset.logs[dataIndex];
                                                //console.log(logs)
                                                return `${context[0].dataset.label}`;
                                            },
                                            label: (context) => {
                                                const logs = context.dataset.logs[context.dataIndex];
                                                return logs.map(log => 
                                                    [`${log.start} - ${log.end}`,
                                                    `${log.notes}`,
                                                    log.notes ? `Notes: ${log.notes}` : null
                                                    ].filter(Boolean)
                                                ).flat();
                                            }
                                        }
                                    },
                                },
                                scales: {
                                    x: {
                                        stacked: true,
                                    },
                                    y: {
                                        stacked: true,
                                    },
                                }
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default UserTimeLog;