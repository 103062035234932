import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useData } from "./DataContext"; // Import the useData hook
import axios from "axios";
import ContactListComponent from "./ContactListComponent";
import ContactDrawer from "./ContactDrawer";
import NotesComponent from "./NotesComponent";
import WebsiteChargesComponent from "./WebsiteChargesComponent";
import WebsiteGeneralComponent from "./WebsiteGeneralComponent";
import WordpressComponent from "./WordpressComponent";
import WebsiteJobsComponent from './WebsiteJobsComponent';
import MaintenanceHistory from './MaintenanceHistory';
import ExportAsCSVButton from "./ExportAsCSVButton";

const defaultLogoUrl =
  "https://sugarprojects.s3.eu-west-1.amazonaws.com/wp-content/uploads/2024/06/18162507/apple-touch-icon.png";

const WebsitePage = () => {
  const { websiteId } = useParams();
  const {
    websites,
    loading,
    addNote,
    addWebsite,
    clients,
    addContact,
    refreshData,
    users,
    decodeHtmlEntities
  } = useData(); // Get context values and functions
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [userImages, setUserImages] = useState({});
  const [clientTitle, setClientTitle] = useState("");
  const [statusMap, setStatusMap] = useState({});
  
  const website = websites.find((w) => w.id === parseInt(websiteId));

  // Set client title if available
  useEffect(() => {
    console.log("W:", website);
  }, [website]);
  
  // Set client title if available
  useEffect(() => {
    if (website?.acf?.client && clients.length > 0) {
      const client = clients.find((c) => c.id === website.acf.client[0]);
      setClientTitle(client ? client.title.rendered : "Not Available");
    }
  }, [website, clients]);

  // Function to check website status through the WordPress proxy endpoint
  async function checkWebsiteStatus(changes) {
    //console.log(changes);
    if(changes?.length > 0){
      console.log(changes[changes.length-1].status == "up")
      if(changes[changes.length-1].status == "up"){
        return true;
      }
      else { return false };
    }
    return true;
  }

  // Update status for the website
  useEffect(() => {
    async function updateStatuses() {
      if (website) {
        const isOnline = await checkWebsiteStatus(website.acf.website_status_changes);
        setStatusMap({ [website.id]: isOnline });
      }
    }
    updateStatuses();
  }, [website]);

  // Fetch user images (if still needed)
  const fetchUserImages = async () => {
    try {
      const images = {};
      users.forEach((user) => {
        if (user.acf && user.acf.user_image) {
          const firstName = user.name.split(" ")[0];
          images[firstName] = user.acf.user_image;
        }
      });
      setUserImages(images);
    } catch (error) {
      console.error("Error fetching user images:", error);
    }
  };

  // Handle adding a new note
  const handleAddNote = async (noteText) => {
    await addNote(websiteId, "website", noteText);
    fetchUserImages(); // Re-fetch images in case of new user
  };

  // Handle adding a new contact
  const handleAddContact = async (contact) => {
    // Prepare the new contact data
    const newContactWithLogo = { ...contact, logo: website.acf?.logo };

    // Use the addContact function from DataContext to add the contact
    const success = await addContact(website.id, newContactWithLogo);
    refreshData("websites");
    
    if (success) {
      setIsDrawerOpen(false); // Close the drawer if successful
    }
  };

  if (loading || !website) return <p>Loading...</p>;

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center gap-x-6">
          <div className="relative">
            <img
              src={website.acf.logo || defaultLogoUrl}
              className="h-10 w-10 rounded-full"
              alt={website.title?.rendered || "Website Logo"}
            />
            <span
              className={`absolute bottom-0 right-0 h-4 w-4 rounded-full ${
                statusMap[website.id] ? "bg-green-500" : "bg-red-500"
              } border-2 border-white`}
            />
          </div>
          <div>
            <h2 className="text-2xl font-semibold">{website.title?.rendered}</h2>
            <p>{website.content?.rendered}</p>
            <p className="text-sm font-medium text-gray-600">
              <a
                href={`/clients/${website?.acf?.client[0]}`}
                className="text-pink-500 hover:underline"
              >
                {decodeHtmlEntities(clientTitle)}
              </a>
            </p>
          </div>
        </div>
        <ExportAsCSVButton websiteData={website}/>
      </div>

      <WebsiteChargesComponent website={website} />
      <div className="flex space-x-4 w-full p-0">
        <div className="w-1/2">
          <WebsiteGeneralComponent website={website} />
        </div>
        <div className="w-1/2 bg-white p-6 rounded-md shadow-md">
          <WordpressComponent externalSiteApiLink={website?.acf?.backend_link} website={website} />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-2/3">
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold text-gray-900 mt-6">Contacts:</h3>
            <button
              onClick={() => setIsDrawerOpen(true)}
              className="rounded-md bg-pink-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 mt-6"
            >
              Add New Contact
            </button>
          </div>
          <ContactListComponent websites={[website]} unique={false} cols={3} />
          <ContactDrawer
            isOpen={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            onSubmit={handleAddContact}
          />
        </div>

        <div className="w-2/3">
          <WebsiteJobsComponent website={website} />
        </div>
      </div>

      <MaintenanceHistory website={website} />

      <h3 className="text-xl font-semibold text-gray-900 mt-6">Notes:</h3>
      <NotesComponent
        notes={website.acf.notes || []}
        handleAddNote={handleAddNote}
        userImages={userImages}
      />
    </div>
  );
};

export default WebsitePage;
