// src/components/LogTable.js
import React, { useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ToDoList from "./ToDoList";

ChartJS.register(ArcElement, Tooltip, Legend);

const LogTable = ({ logs, setLogs, targetHours, setTargetHours, job }) => {
    const [logsToDelete, setLogsToDelete] = useState([]);
    const [showAllLogs, setShowAllLogs] = useState(false);
    const username = localStorage.getItem("username");

    

    // Helper function to calculate the hours between two times
    const calculateHours = (start, end) => {
        if (!start || !end) return 0;
        const startTime = new Date(`1970-01-01T${start}:00`);
        const endTime = new Date(`1970-01-01T${end}:00`);
        const diff = (endTime - startTime) / (1000 * 60 * 60); // difference in hours
        return diff > 0 ? diff : 0;
    };

    // Sort logs by date and start_time (latest at the top)
    const sortedLogs = [...logs].sort((a, b) => {
        const dateComparison = new Date(b.date) - new Date(a.date);
        if (dateComparison !== 0) return dateComparison;

        // Compare start_time if dates are the same
        const startTimeA = new Date(`1970-01-01T${a.start_time || "00:00"}:00`);
        const startTimeB = new Date(`1970-01-01T${b.start_time || "00:00"}:00`);
        return startTimeB - startTimeA;
    });

    // Calculate hours logged by each unique user
    const hoursByUser = sortedLogs.reduce((acc, log) => {
        const { name, start_time, end_time } = log;
        const hours = calculateHours(start_time, end_time);
        if (!acc[name]) acc[name] = 0;
        acc[name] += hours;
        return acc;
    }, {});

    // Calculate total hours logged
    const totalHours = Object.values(hoursByUser).reduce((sum, hours) => sum + hours, 0);

    // Update log entry fields dynamically
    const handleInputChange = (index, field, value) => {
        const updatedLogs = sortedLogs.map((log, i) =>
            i === index ? { ...log, [field]: value } : log
        );
        setLogs(updatedLogs);
    };

    // Add a new log row with today's date and user's name
    const handleAddLog = () => {
        const today = new Date().toISOString().split("T")[0];
        setLogs([
            { date: today, name: username || "", start_time: "", end_time: "", details: "" },
            ...logs
        ]);
    };

    // Remove log entry from logs
    const handleDeleteLog = (index) => {
        const updatedLogs = [...logs];
        const [deletedLog] = updatedLogs.splice(index, 1);
        setLogs(updatedLogs);
        if (deletedLog && deletedLog.id) {
            setLogsToDelete([...logsToDelete, deletedLog.id]);
        }
    };

    // Determine how many logs to display
    const visibleLogs = showAllLogs ? sortedLogs : sortedLogs.slice(0, 4);

    // Prepare data for Pie chart
    const pieData = {
        labels: Object.keys(hoursByUser),
        datasets: [
            {
                data: Object.values(hoursByUser),
                backgroundColor: [
                    "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"
                ],
                hoverBackgroundColor: [
                    "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"
                ],
            },
        ],
    };

    // Progress towards target hours
    const progressPercentage = Math.min((totalHours / targetHours) * 100, 100);

    return (
        <div className="bg-white shadow-sm rounded-lg p-4 pt-10">
            <div className="flex align-center justify-between">
                <h2 className="text-lg font-semibold text-gray-900 mb-4">Time Log</h2>
                <div className="flex justify-end">
                    <button
                        onClick={handleAddLog}
                        className="text-pink-600 hover:text-pink-400 font-semibold"
                    >
                        Add Log
                    </button>
                </div>
            </div>
            <table className="min-w-full divide-y divide-gray-300 mb-4">
                <thead>
                    <tr>
                        <th className="px-3 py-2 text-left text-sm font-semibold text-gray-900">Date</th>
                        <th className="px-3 py-2 text-left text-sm font-semibold text-gray-900">Name</th>
                        <th className="px-3 py-2 text-left text-sm font-semibold text-gray-900">Start Time</th>
                        <th className="px-3 py-2 text-left text-sm font-semibold text-gray-900">End Time</th>
                        <th className="px-3 py-2 text-left text-sm font-semibold text-gray-900">Details</th>
                        <th className="px-3 py-2 text-right text-sm font-semibold text-gray-900">Actions</th>
                    </tr>
                </thead>
                <tbody className="">
                    {visibleLogs.map((log, index) => (
                        <tr key={index}>
                            <td className="px-3 py-2">
                                <input
                                    type="date"
                                    className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                                    value={log.date || ""}
                                    onChange={(e) => handleInputChange(index, "date", e.target.value)}
                                />
                            </td>
                            <td className="px-3 py-2">
                                <input
                                    type="text"
                                    className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                                    value={log.name || ""}
                                    onChange={(e) => handleInputChange(index, "name", e.target.value)}
                                />
                            </td>
                            <td className="px-3 py-2">
                                <input
                                    type="time"
                                    className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                                    value={log.start_time || ""}
                                    onChange={(e) => handleInputChange(index, "start_time", e.target.value)}
                                />
                            </td>
                            <td className="px-3 py-2">
                                <input
                                    type="time"
                                    className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                                    value={log.end_time || ""}
                                    onChange={(e) => handleInputChange(index, "end_time", e.target.value)}
                                />
                            </td>
                            <td className="px-3 py-2">
                                <input
                                    type="text"
                                    className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                                    value={log.details || ""}
                                    onChange={(e) => handleInputChange(index, "details", e.target.value)}
                                />
                            </td>
                            <td className="px-3 py-2 text-right">
                                <button
                                    onClick={() => handleDeleteLog(index)}
                                    className="text-red-500 hover:text-red-700"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="w-5 h-5 inline"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                        />
                                    </svg>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {logs.length > 4 && (
                <div className="flex justify-center mt-4">
                    <button
                        onClick={() => setShowAllLogs(!showAllLogs)}
                        className="text-pink-600 hover:text-pink-400 font-semibold"
                    >
                        {showAllLogs ? "Show Less" : "Show More"}
                    </button>
                </div>
            )}

            <div className="flex align-center justify-center gap-20">

                {/* Display the summary of hours */}
                <div className="mt-8">
                    {/* Target hours input */}
                    <div className="mb-8">
                        <label className="text-sm font-semibold text-gray-700">Target Hours: </label>
                        <input
                            type="number"
                            value={targetHours}
                            onChange={(e) => setTargetHours(e.target.value)}
                            className="ml-2 w-20 p-1 border rounded"
                        />
                    </div>
                    <ul>
                        {Object.entries(hoursByUser).map(([name, hours]) => (
                            <li key={name} className="text-sm text-gray-700">
                                {name}: {hours.toFixed(2)} hours
                            </li>
                        ))}
                    </ul>
                    <p className="text-sm text-gray-700 mt-2">
                        Total Hours Logged: {totalHours.toFixed(2)} hours
                    </p>

                    {/* Progress Bar */}
                    <div className="mt-4 w-64">
                        <div className="relative pt-1">
                            <div className="flex mb-2 items-center justify-between">
                                <div>
                                    <span className="text-sm font-semibold inline-block text-pink-600">
                                        Hours Used: ({Math.round(progressPercentage)}%)
                                    </span>
                                </div>
                            </div>
                            <div className="overflow-hidden h-4 mb-4 text-xs flex rounded bg-pink-200">
                                <div
                                    style={{ width: `${progressPercentage}%` }}
                                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-pink-600"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Pie Chart */}
                <div className="mt-4 ">
                    <Pie data={pieData} />
                </div>
                
                <div className="flex-1">
                <ToDoList job={job} />
                </div>
            </div>
        </div>
    );
};

export default LogTable;