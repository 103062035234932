// src/components/TeamPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useData } from './DataContext';
import UserTimeLog from './UserTimeLog';
import AnnualLeaveCalander from './AnnualLeaveCalander';

const TeamPage = () => {


  return (
    <div className="bg-white shadow sm:rounded-lg">
      <AnnualLeaveCalander />
      <UserTimeLog />
    </div>
  );
};

export default TeamPage;