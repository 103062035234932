// src/components/InvoicesPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useData } from './DataContext'; 

const InvoicesPage = () => {
  const { invoices, setInvoices } = useData();
  const [searchTerm, setSearchTerm] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [notification, setNotification] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [invoiceToDelete, setInvoiceToDelete] = useState(null);

  console.log(invoices);

  const handleInputChange = (id, field, value) => {
    setInvoices(invoices.map(invoice => {
      if (invoice.id === id) {
        if (field.includes('acf.')) {
          const acfField = field.split('.')[1]; // Extract the field name (e.g., 'instructions')
          return { ...invoice, acf: { ...invoice.acf, [acfField]: value } }; // Update only the acf field
        } else if (field === 'title.rendered') {
          // Explicitly handle the nested title.rendered field
          return { ...invoice, title: { ...invoice.title, rendered: value } };
        }
        return { ...invoice, [field]: value }; // For other top-level fields
      }
      return invoice; // Return the original invoice if it doesn't match
  }))};
    
  

  const saveInvoice = async (invoice) => {
    const token = localStorage.getItem("token");

    try {
      const data = {
        title: invoice?.title?.rendered || "Untitled Invoice",
        acf: invoice.acf,
        status: "publish"
      };

      if (invoice.id) {
        await axios.put(
          `https://api.crm.sugarprojects.com/wp-json/wp/v2/invoice/${invoice.id}`,
          data,
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } else {
        const response = await axios.post(
          'https://api.crm.sugarprojects.com/wp-json/wp/v2/invoice',
          data,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        invoice.id = response.data.id;
      }
      setNotification({ message: `Invoice "${invoice.acf.title}" saved successfully!`, type: 'success' });
    } catch (error) {
      console.error('Error saving Invoice:', error);
      setNotification({ message: 'Error saving Invoice', type: 'error' });
    }
  };

  const saveAllInvoices = async () => {
    setIsSaving(true);
    try {
      await Promise.all(invoices.map(saveInvoice));
      setNotification({ message: "All Invoices saved successfully!", type: "success" });
    } catch (error) {
      console.error('Error saving all Invoices:', error);
      setNotification({ message: 'Error saving all Invoices', type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };

  const addNewInvoice = () => {
    const newInvoice = {
      acf: {
        title: '',
        content: '',
      },
    };
    setInvoices([newInvoice, ...invoices]);
  };

  const openDeleteModal = (invoice) => {
    setInvoiceToDelete(invoice);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setInvoiceToDelete(null);
    setShowDeleteModal(false);
  };

  const confirmDeleteInvoice = async () => {
    const invoice = invoiceToDelete;
    const token = localStorage.getItem("token");

    try {
      if (invoice.id) {
        await axios.delete(
          `https://api.crm.sugarprojects.com/wp-json/wp/v2/invoice/${invoice.id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }
      setInvoices(invoices.filter(item => item.id !== invoice.id));
      setNotification({ message: `Invoice "${invoice.acf.title}" deleted successfully!`, type: 'success' });
    } catch (error) {
      console.error('Error deleting Invoice:', error);
      setNotification({ message: 'Error deleting Invoice', type: 'error' });
    } finally {
      closeDeleteModal();
    }
  };

  return (
    <div className="bg-white shadow sm:rounded-lg p-6">
      {notification && (
        <div aria-live="assertive" className="pointer-events-none fixed top-12 right-0 w-full flex items-end px-4 py-6 sm:items-start sm:p-6 z-10">
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            <div className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition ease-out duration-300 ${notification.type === 'error' ? 'text-red-600' : 'text-green-600'}`}>
              <div className="p-4">
                <div className="flex items-start">
                  <p className="text-sm font-medium text-gray-900">{notification.message}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white">
        <div className="flex flex-wrap justify-between sm:flex-nowrap mb-4">
          <h1 className="text-2xl font-semibold leading-6 text-gray-900">Invoices</h1>
          <div className="flex-shrink-0">
            <button onClick={addNewInvoice} className="mr-4 p-2 bg-pink-600 hover:bg-pink-500 text-white rounded shadow">Add New Invoice</button>
            <button onClick={saveAllInvoices} disabled={isSaving} className="p-2 bg-pink-600 hover:bg-pink-500 text-white rounded shadow">
              {isSaving ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </div>
      </div>

      <div className="space-y-6">
        {invoices.map((invoice, index) => (
          <div key={index} className="p-4 bg-white border-t-2 border-gray-200 relative">
            <div className="flex align-center justify-between mb-6">
              <input
                type="text"
                value={invoice?.title?.rendered || ''} // Bind input to invoice.title.rendered
                onChange={(e) => handleInputChange(invoice.id, 'title.rendered', e.target.value)} // Update invoice.title.rendered on change
                className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600 mr-4"
              />
              <button
                onClick={() => openDeleteModal(invoice)}
                className="text-red-600 hover:text-red-800"
              >
                Delete
              </button>
            </div>


            <label className="">
              Content
              <textarea
                value={invoice?.acf?.content || ''} // Bind input to invoice.acf.instructions
                onChange={(e) => handleInputChange(invoice.id, 'acf.content', e.target.value)} // Update acf.instructions on change
                className="mt-2 h-96 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
              />
            </label>
          </div>
        ))}
      </div>
      {showDeleteModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-lg text-center max-w-sm mx-auto">
            <h3 className="text-lg font-semibold">Are you sure?</h3>
            <p className="my-4">Do you really want to delete "{invoiceToDelete?.acf.title}"?</p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={confirmDeleteInvoice}
                className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-500"
              >
                Yes, delete
              </button>
              <button
                onClick={closeDeleteModal}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoicesPage;