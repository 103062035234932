import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Field, Label, Switch } from '@headlessui/react';
import { useMaintenanceCount } from './MaintenanceCountContext';
import { useData } from './DataContext';


const MaintanenceList = ({ delete: isDeleteEnabled, onDelete, showSearchBar }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const { websites, setWebsites, refreshData } = useData();
    const [filteredWebsites, setFilteredWebsites] = useState(websites);
    const [notification, setNotification] = useState(null);
    const [enabled, setEnabled] = useState(false);
    const { fetchMaintenanceCount } = useMaintenanceCount();
    const [selectedWebsite, setSelectedWebsite] = useState(null);
    const [pluginChanges, setPluginChanges] = useState([]);
    const [maintenanceLog, setMaintenanceLog] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [latestPluginsState, setLatestPluginsState] = useState([]);
    const [latestWordpress, setLatestWordpress] = useState();
    const [latestYootheme, setLatestYootheme] = useState();

    const username = localStorage.getItem("username");

    useEffect(() => {
        console.log(isModalOpen);
    }, [isModalOpen]);

    // Calculate days since a specific date
    const daysSince = (dateString) => {
        let date;

        // Handle yyyymmdd format (e.g., 20240412)
        if (dateString.length === 8 && /^\d{8}$/.test(dateString)) {
            // Convert yyyymmdd to yyyy-mm-dd (e.g., 20240412 -> 2024-04-12)
            dateString = dateString.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
        }

        // If the date is in ISO format (yyyy-mm-dd)
        if (dateString.includes('-')) {
            date = new Date(dateString); // directly create Date from ISO string
        } else {
            // If the date is in dd/mm/yyyy format
            const [day, month, year] = dateString.split('/').map(part => parseInt(part, 10));
            date = new Date(Date.UTC(year, month - 1, day)); // month - 1 because months are 0-indexed in JavaScript
        }

        // Check if the date is valid
        if (isNaN(date)) {
            console.error("Invalid date:", dateString);
            return NaN; // Return NaN or handle the invalid date as needed
        }

        // Get today's date and set both dates to midnight (UTC) to avoid time zone discrepancies
        const today = new Date();
        today.setUTCHours(0, 0, 0, 0);

        // Set the target date to midnight (UTC) as well
        date.setUTCHours(0, 0, 0, 0);

        // Calculate the difference in time
        const diffTime = today - date;
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        return diffDays;
    };

    // Filter websites based on search query, maintenance status, and time since maintenance
    useEffect(() => {
        let results = websites.filter(website => {
            const { title, acf } = website;
            const matchesTitle = title.rendered.toLowerCase().includes(searchQuery.toLowerCase());
            const matchesDescription = acf?.description?.toLowerCase().includes(searchQuery.toLowerCase());
            const matchesCategory = acf?.category?.toLowerCase().includes(searchQuery.toLowerCase());
            const matchesClient = acf?.client_name?.toLowerCase().includes(searchQuery.toLowerCase());

            // Only include websites where maintenance is true
            return acf?.maintenance && (matchesTitle || matchesDescription || matchesCategory || matchesClient);
        });

        // Filter by websites that have not been maintained for more than 30 days if toggle is on
        if (!enabled) {
            results = results.filter(website => daysSince(website.acf.last_maintenance) > 30);
        }

        // Sort by the longest time since maintenance
        results = results.sort((a, b) => {
            return daysSince(b.acf.last_maintenance) - daysSince(a.acf.last_maintenance);
        });

        setFilteredWebsites(results);
    }, [searchQuery, websites, enabled]);

    // Format date to ISO 8601 (yyyy-mm-dd) for backend storage
    const formatDateForBackend = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two-digit month
        const day = String(date.getDate()).padStart(2, '0'); // Ensure two-digit day
        return `${year}-${month}-${day}`;
    };

    // Function to update the last maintenance date to today in both backend and local state
    const handleUpdateLastMaintenance = async (websiteId) => {
        const todayDate = formatDateForBackend(new Date()); // Use the formatted date for backend
        console.log("today:", todayDate)

        try {
            const token = localStorage.getItem("token");

            if (!token) {
                console.error("No token found in localStorage.");
                setNotification({ message: "Authentication token is missing.", type: "error" });
                return;
            }

            // Update backend ACF field for last_maintenance
            await axios.post(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${websiteId}`,
                { acf: { last_maintenance: todayDate } }, // Send formatted date here
                { headers: { Authorization: `Bearer ${token}` } }
            );

            // Update local state to reflect the change in the UI immediately
            const updatedWebsites = websites.map((website) =>
                website.id === websiteId
                    ? { ...website, acf: { ...website.acf, last_maintenance: todayDate } }
                    : website
            );

            // Update both websites and filteredWebsites states
            setWebsites(updatedWebsites);

            // Reapply the filter based on the toggle state after the update
            let results = updatedWebsites.filter((website) => {
                const { title, acf } = website;
                const matchesTitle = title.rendered.toLowerCase().includes(searchQuery.toLowerCase());
                const matchesDescription = acf?.description?.toLowerCase().includes(searchQuery.toLowerCase());
                const matchesCategory = acf?.category?.toLowerCase().includes(searchQuery.toLowerCase());
                const matchesClient = acf?.client_name?.toLowerCase().includes(searchQuery.toLowerCase());

                // Only include websites where maintenance is true
                return acf?.maintenance && (matchesTitle || matchesDescription || matchesCategory || matchesClient);
            });

            // Filter by websites that have not been maintained for more than 30 days if the toggle is off
            if (!enabled) {
                results = results.filter((website) => {
                    // Ensure that last_maintenance exists and is valid
                    return website.acf?.last_maintenance && daysSince(website.acf.last_maintenance) > 30;
                });
            }

            // Sort by the longest time since maintenance
            results = results.sort((a, b) => {
                return daysSince(b.acf.last_maintenance) - daysSince(a.acf.last_maintenance);
            });

            // Update filtered websites state
            setFilteredWebsites(results);

            // Show success notification
            setNotification({ message: "Maintenance date updated successfully!", type: "success" });

            // Fetch maintenance count (assuming this is defined somewhere in your context)
            fetchMaintenanceCount();

        } catch (error) {
            console.error("Error updating last maintenance:", error);
            setNotification({ message: "Failed to update maintenance date.", type: "error" });
        }

        // Hide notification after 3 seconds
        setTimeout(() => setNotification(null), 3000);
    };



    // Fetch latest plugins with token authentication
    const fetchLatestPlugins = async (website) => {
        try {
            console.log("fetching")

            // Fetch plugins data
            const pluginResponse = await axios.get(`${website?.acf?.backend_link}/wp-json/wp/v2/plugin_info`, {
                params: {
                    per_page: 100,
                    _fields: 'id,title,meta'
                }
            });

            console.log(pluginResponse)

            const plugins = pluginResponse.data.map((plugin) => ({
                name: plugin?.title?.rendered,
                version: plugin?.meta?._plugin_version,
            }));

            return plugins;

        } catch (error) {
            console.error("Failed to fetch plugins or WordPress version:", error.response ? error.response.data : error.message);
            return [];
        }
    };

    const comparePlugins = (currentWordpress, newWordpress, currentYootheme, newYootheme, currentPlugins, newPlugins) => {
        const changes = [];
    
        // Compare WordPress versions
        if (!currentWordpress && newWordpress) {
            changes.push(`Added WordPress version ${newWordpress}`);
        } else if (currentWordpress && newWordpress && currentWordpress !== newWordpress) {
            changes.push(`WordPress updated from ${currentWordpress} to ${newWordpress}`);
        }
    
        console.log(currentYootheme, newYootheme)
        // Compare YooTheme versions
        if (!currentYootheme && newYootheme) {
            changes.push(`Added YooTheme version ${newYootheme}`);
        } else if (currentYootheme && newYootheme && (currentYootheme !== newYootheme)) {
            changes.push(`YooTheme updated from ${currentYootheme} to ${newYootheme}`);
        }
    
        // Check for updated and removed plugins
        currentPlugins.forEach((current) => {
            const updated = newPlugins.find((plugin) => plugin.name === current.name);
            if (!updated) {
                changes.push(`Removed: ${current.name} (version ${current.version})`);
            } else if (current.version !== updated.version) {
                changes.push(`${current.name} plugin updated from ${current.version} to ${updated.version}`);
            }
        });
    
        // Check for newly added plugins
        newPlugins.forEach((newPlugin) => {
            if (!currentPlugins.find((plugin) => plugin.name === newPlugin.name)) {
                changes.push(`Added: ${newPlugin.name} (version ${newPlugin.version})`);
            }
        });
    
        return changes;
    };
    
    
    

    // Open modal with maintenance log details
    const handleMaintenanceClick = async (website) => {
        const latestPlugins = await fetchLatestPlugins(website);
    
        let latestWordpress = null;
        let latestYootheme = null;
    
        // Fetch WordPress version
        try {
            const wpResponse = await fetch(`${website?.acf?.backend_link}/wp-json/custom/v1/version`);
            if (!wpResponse.ok) {
                throw new Error(`HTTP error! status: ${wpResponse.status}`);
            }
            const data = await wpResponse.json();
            latestWordpress = data.version;
            setLatestWordpress(data.version);
        } catch (error) {
            console.error('Error fetching WordPress version:', error);
        }
    
        // Fetch Yootheme version
        try {
            const ytResponse = await fetch(`${website?.acf?.backend_link}/wp-json/custom/v1/yootheme-version`);
            if (!ytResponse.ok) {
                throw new Error(`HTTP error! status: ${ytResponse.status}`);
            }
            const data2 = await ytResponse.json();
            latestYootheme = data2.version;
            setLatestYootheme(data2.version);
        } catch (error) {
            console.error('Error fetching Yootheme version:', error);
        }
    
        // Log results of fetches
        console.log(latestPlugins, latestWordpress, latestYootheme);
    
        // Store latest plugins in state
        setLatestPluginsState(latestPlugins);
    
        const pluginDiff = comparePlugins(
            website?.acf?.wordpress_version,
            latestWordpress,
            website?.acf?.yootheme_version,
            latestYootheme,
            website?.acf?.plugins || [],
            latestPlugins
        );
    
        console.log(pluginDiff);
    
        setPluginChanges(pluginDiff);
        setMaintenanceLog({
            user: username,
            date: new Date().toISOString().split("T")[0],
            end_time: snapToNearest15Minutes(new Date().toISOString().split("T")[1].slice(0, 5)),
            start_time: "", // Filled manually by the user
            details: pluginDiff.join("<br />"),
        });
        setSelectedWebsite(website);
    
        console.log("Opening Modal...");
        setIsModalOpen(true);
    };

    

    const handleSaveMaintenanceLog = async () => {
        const token = localStorage.getItem("token");
        refreshData("jobs");
        refreshData("websites");
    
        try {
            // Step 1: Update website with maintenance log and plugins
            await axios.post(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${selectedWebsite.id}`,
                {
                    acf: {
                        plugins: latestPluginsState.map((plugin) => ({
                            name: plugin.name,
                            version: plugin.version,
                        })),
                        wordpress_version: latestWordpress,
                        yootheme_version: latestYootheme,
                        maintenance_logs: [
                            ...(selectedWebsite.acf.maintenance_logs || []),
                            maintenanceLog,
                        ],
                    },
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
    
            // Step 2: Update the job's logs for maintenance (id: 435)
            const jobId = 435; // Maintenance job ID
            const taskToAdd = {
                date: maintenanceLog.date,
                start_time: maintenanceLog.start_time,
                end_time: maintenanceLog.end_time,
                details: `${maintenanceLog.details} (${selectedWebsite?.title?.rendered})`,
                name: maintenanceLog.user,
            };
            
    
            // Fetch the existing logs for the job
            const jobResponse = await axios.get(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/jobs/${jobId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            const existingLogs = jobResponse.data.acf.logs || [];
            const updatedLogs = [...existingLogs, taskToAdd];
    
            // Update the job's logs
            await axios.post(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/jobs/${jobId}`,
                {
                    acf: {
                        logs: updatedLogs,
                    },
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
    
            alert("Maintenance log saved and job updated successfully!");
            setIsModalOpen(false);
            handleUpdateLastMaintenance(selectedWebsite.id);
            refreshData("jobs");
            refreshData("websites");
        } catch (error) {
            console.error("Failed to save maintenance log or update job:", error);
            alert("Error saving maintenance log.");
        }
    };
    
    
    const snapToNearest15Minutes = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        const totalMinutes = hours * 60 + minutes;
    
        // Snap to the nearest 15 minutes
        const snappedMinutes = Math.round(totalMinutes / 15) * 15;
    
        const snappedHours = Math.floor(snappedMinutes / 60);
        const snappedRemainderMinutes = snappedMinutes % 60;
    
        // Format back to "HH:mm" format
        return `${String(snappedHours).padStart(2, '0')}:${String(snappedRemainderMinutes).padStart(2, '0')}`;
    };
    

    return (
        <div className="bg-white shadow sm:rounded-lg">
            {/* Notification Display */}
            {notification && (
                <div className={`notification ${notification.type === 'error' ? 'text-red-600' : 'text-green-600'}`}>
                    {notification.message}
                </div>
            )}
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                    <div className="ml-4 mt-4">
                        <h1 className="text-2xl font-semibold leading-6 text-gray-900">Maintenance</h1>
                    </div>
                    <div className="ml-4 mt-4 flex-shrink-0">
                        <Field className="flex items-center">
                            <Switch
                                checked={enabled}
                                onChange={setEnabled}
                                className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none data-[checked]:bg-pink-600"
                            >
                                <span
                                    aria-hidden="true"
                                    className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                />
                            </Switch>
                            <Label as="span" className="ml-3 text-sm">
                                <span className="font-medium text-gray-900">Show All</span>{' '}
                            </Label>
                        </Field>
                    </div>
                </div>
            </div>

            {/* Conditionally render Search Bar */}
            {showSearchBar && (
                <div className="bg-white px-4 py-4 sm:px-6">
                    <input
                        type="text"
                        placeholder="Search websites..."
                        className="w-full border-gray-300 rounded-md shadow-sm focus:border-pink-500 focus:ring-pink-500"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
            )}

            {/* Website List */}
            <ul role="list" className="divide-y divide-gray-100 bg-white shadow rounded-lg">
                {filteredWebsites.map((website) => (
                    <li key={website.id} className="flex items-center justify-between gap-x-6 py-5 px-10">
                        <div className="min-w-0">
                            <div className="flex gap-x-6">
                                <img
                                    src={website.acf.logo}
                                    style={{ height: "50px", width: "50px" }}
                                    alt={`${website.title?.rendered || 'Untitled Website'} Logo`}
                                />
                                <div className="flex flex-col">
                                    <div className="flex items-start gap-x-3">
                                        <a
                                            href={website.acf.frontend_link}
                                            target="_blank"
                                            className="text-sm font-semibold text-pink-700 hover:underline"
                                        >
                                            {website.title?.rendered || "Untitled Website"}
                                        </a>
                                        <p className="text-sm font-semibold text-pink-700">
                                            <Link to={`/websites/${website.id}`} className="hover:underline">
                                                Details
                                            </Link>
                                        </p>
                                    </div>

                                    <div className="mt-1 flex items-center gap-x-2 text-xs text-gray-500">
                                        <p className="whitespace-nowrap">
                                            Last Maintenance: <time dateTime={website.acf.last_maintenance}>{website.acf.last_maintenance ? `${daysSince(website.acf.last_maintenance)} days ago` : 'N/A'}</time>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            className="bg-pink-600 hover:bg-pink-500 text-white px-3 py-1.5 rounded text-sm"
                            onClick={() => handleMaintenanceClick(website)}
                        >
                            Perform Maintenance
                        </button>
                    </li>
                ))}
            </ul>

            {/* Modal for Maintenance Logs */}
            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded shadow-lg">
                        <h2>Maintenance Log</h2>
                        <textarea
                            className="w-full h-32 border"
                            value={maintenanceLog.details}
                            onChange={(e) =>
                                setMaintenanceLog({ ...maintenanceLog, details: e.target.value })
                            }
                        />
                        <div className="flex justify-between gap-5">
                            <label>
                                Date:
                                <input
                                    type="date"
                                    value={maintenanceLog.date}
                                    onChange={(e) =>
                                        setMaintenanceLog({ ...maintenanceLog, date: e.target.value })
                                    }
                                />
                            </label>
                            <label>
                                Start Time:
                                <input
                                    type="time"
                                    value={maintenanceLog.start_time}
                                    onChange={(e) => {
                                        const snappedTime = snapToNearest15Minutes(e.target.value);
                                        setMaintenanceLog({ ...maintenanceLog, start_time: snappedTime });
                                    }}
                                />
                            </label>
                            <label>
                                End Time:
                                <input
                                    type="time"
                                    value={maintenanceLog.end_time}
                                    onChange={(e) => {
                                        const snappedTime = snapToNearest15Minutes(e.target.value);
                                        setMaintenanceLog({ ...maintenanceLog, end_time: snappedTime });
                                    }}
                                />
                            </label>
                        </div>
                        <div className="flex justify-between mt-4">
                            <button
                                className="bg-gray-300 hover:bg-gray-200 text-gray-700 px-4 py-2 rounded"
                                onClick={() => setIsModalOpen(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-pink-600 hover:bg-pink-500 text-white px-4 py-2 rounded"
                                onClick={handleSaveMaintenanceLog}
                            >
                                Save Log
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MaintanenceList;
