import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import LogTable from "./LogTable";
import { useData } from './DataContext';
import axios from 'axios';

const WebsiteAutocomplete = ({ websites, selectedWebsite, setSelectedWebsite }) => {
    const [inputValue, setInputValue] = useState('');
    const [recommendations, setRecommendations] = useState([]);
  
    // Effect to set initial input value when selectedWebsite changes
    useEffect(() => {
      if (selectedWebsite) {
        const matchedWebsite = websites.find(website => website.id === selectedWebsite);
        if (matchedWebsite) {
          setInputValue(matchedWebsite.title.rendered);
        }
      } else {
        setInputValue('');
      }
    }, [selectedWebsite, websites]);
  
    const handleInputChange = (e) => {
      const value = e.target.value;
      setInputValue(value);
  
      // Filter websites that start with the input value
      const filteredWebsites = websites.filter(website => 
        website.title.rendered.toLowerCase().startsWith(value.toLowerCase())
      );
  
      setRecommendations(value ? filteredWebsites : []);
    };
  
    const handleRecommendationSelect = (website) => {
      setSelectedWebsite(website.id);
      setInputValue(website.title.rendered);
      setRecommendations([]);
    };
  
    return (
      <div className="w-full relative">
        <input
          type="text"
          id="website"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Select Website"
          className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
          required
        />
        {recommendations.length > 0 && (
          <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-auto">
            {recommendations.map((website) => (
              <li 
                key={website.id} 
                onClick={() => handleRecommendationSelect(website)}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              >
                {website.title.rendered}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const JobPage = () => {
    const { websites, jobs, loading, error, refreshData, users } = useData();
    const { jobId } = useParams();

    const [job, setJob] = useState(null);
    const [logs, setLogs] = useState([]);
    const [localError, setLocalError] = useState(null);
    const [assignedUsers, setAssignedUsers] = useState([]);

    // Existing state
    const [title, setTitle] = useState("");
    const [onedriveLink, setOnedriveLink] = useState("");
    const [selectedWebsite, setSelectedWebsite] = useState("");
    const [poNumber, setPoNumber] = useState("");
    const [targetHours, setTargetHours] = useState("40");
    const [status, setStatus] = useState("");
    const [color, setColor] = useState("#ffffff");

    //useEffect(() => {
    //    console.log(users);
    //}, [users])

    useEffect(() => {
        if (loading) return;

        const foundJob = jobs.find(job => job.id === parseInt(jobId, 10));
        if (foundJob) {
            setJob(foundJob);
            setLogs(foundJob.acf?.logs || []);
            setTitle(foundJob.title?.rendered || "");
            setPoNumber(foundJob.acf?.po_number || "");
            setOnedriveLink(foundJob.acf?.onedrive_link || "");
            setSelectedWebsite(foundJob.acf?.website || "");
            setStatus(foundJob.acf?.status || "");
            setTargetHours(foundJob.acf?.target_hours || "40");
            setColor(foundJob.acf?.colour);
            
            // Initialize assigned users
            const loadAssignedUsers = async () => {
                try {
                    const token = localStorage.getItem('token');
                    const assignedUserIds = [];
                    
                    // Check each user to see if they have this job assigned
                    for (const user of users) {
                        const userJobs = user.acf?.jobs || [];
                        if (userJobs.includes(parseInt(jobId, 10))) {
                            assignedUserIds.push(user.id);
                        }
                    }
                    
                    setAssignedUsers(assignedUserIds);
                } catch (err) {
                    console.error('Error loading assigned users:', err);
                }
            };
            
            loadAssignedUsers();
        } else {
            setLocalError("Job not found in context.");
        }
    }, [jobId, jobs, loading, users]);

    const handleSaveChanges = async () => {
        try {
            refreshData("users");
            refreshData("jobs");
            const token = localStorage.getItem('token');
    
            // Save changes to the job first
            await axios.post(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/jobs/${jobId}`,
                {
                    acf: {
                        logs,
                        target_hours: targetHours,
                        status,
                        website: selectedWebsite,
                        po_number: poNumber,
                        onedrive_link: onedriveLink,
                        colour: color,
                    },
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            // Update user assignments
            for (const user of users) {
                const isAssigned = assignedUsers.includes(user.id);
                const userJobs = user.acf?.jobs || [];
                const hasJob = userJobs.includes(parseInt(jobId, 10));
                
                if (isAssigned !== hasJob) {
                    // Only update if there's a change needed
                    let newJobs = isAssigned 
                        ? [...userJobs, parseInt(jobId, 10)]
                        : userJobs.filter(id => id !== parseInt(jobId, 10));
                    
                    // If array is empty, just send an array with a 0 since we need an integer
                    if (newJobs.length === 0) {
                        newJobs = [""];
                    }

                    console.log(user, hasJob, isAssigned, newJobs)

                    await axios.post(
                        `https://api.crm.sugarprojects.com/wp-json/wp/v2/users/${user.id}`,
                        {
                            acf: {
                                jobs: newJobs // Ensure all values are integers
                            }
                        },
                        { headers: { 
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }}
                    );
                }
            }

            // Create a map to collect logs per user
            const userLogsMap = new Map();

            // Process all logs first
            for (const log of logs) {
                const { name, date, start_time, end_time, details } = log;
                const userSlug = name.toLowerCase().replace(/\s+/g, '-');
                
                const user = users.find(u => u.slug === userSlug);
                if (!user) {
                    console.warn(`User with slug "${userSlug}" not found.`);
                    continue;
                }
                
                // Get or initialize user's logs
                if (!userLogsMap.has(user.id)) {
                    userLogsMap.set(user.id, user.acf?.logs || []);
                }
                
                const existingLogs = userLogsMap.get(user.id);
                const existingLogIndex = existingLogs.findIndex(
                    l => l.date === date && l.start_time === start_time
                );
                
                if (existingLogIndex !== -1) {
                    existingLogs[existingLogIndex] = { date, start_time, end_time, details };
                } else {
                    existingLogs.push({ date, start_time, end_time, details });
                }
            }
            
            // Make one update per user with all their log changes
            const updatePromises = Array.from(userLogsMap.entries()).map(([userId, userLogs]) =>
                axios.post(
                    `https://api.crm.sugarprojects.com/wp-json/wp/v2/users/${userId}`,
                    {
                        acf: {
                            logs: userLogs
                        }
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                )
            );
    
            refreshData("users");
            refreshData("jobs");
            //alert("Changes saved successfully!");
        } catch (err) {
            console.error("Error saving job data:", err);
            console.error("Error details:", err.response?.data || err.message);
            alert("Failed to save changes. Check console for details.");
        }
    };

    const handleUserToggle = (userId) => {
        setAssignedUsers(prev => {
            if (prev.includes(userId)) {
                return prev.filter(id => id !== userId);
            } else {
                return [...prev, userId];
            }
        });
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="bg-white shadow sm:rounded-lg p-4">
            {/* Existing header with save button */}
            <div className="m-4 flex justify-between align-center">
                <div className='flex items-center'>
                    <h1 className="text-2xl font-semibold leading-6 text-gray-900">{title}</h1>
                    <input
                        type="color"
                        id="color-picker"
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                        className="ml-1 block w-6 h-6 border-0 rounded"
                    />
                </div>
                <button
                    onClick={handleSaveChanges}
                    className="rounded-md bg-pink-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500"
                >
                    Save Changes
                </button>
            </div>

            <div className="mt-6 px-4">
                <h2 className="text-lg font-semibold mb-3">Assign Users</h2>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {users.map(user => (
                        <div key={user.id} className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                id={`user-${user.id}`}
                                checked={assignedUsers.includes(user.id)}
                                onChange={() => handleUserToggle(user.id)}
                                className="rounded border-gray-300 text-pink-600 focus:ring-pink-500"
                            />
                            <label htmlFor={`user-${user.id}`} className="text-sm text-gray-700">
                                {user.name}
                            </label>
                        </div>
                    ))}
                </div>
            </div>

            <LogTable logs={logs} setLogs={setLogs} targetHours={targetHours} setTargetHours={setTargetHours} job={job}/>
        </div>
    );
};

export default JobPage;