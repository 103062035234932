import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useData } from './DataContext';

const SOPsPage = () => {
  const { sops, setSops, categories, refreshData } = useData();
  const [searchTerm, setSearchTerm] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [notification, setNotification] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sopToDelete, setSopToDelete] = useState(null);
  const [categoryMap, setCategoryMap] = useState({});
  const [newCategoryName, setNewCategoryName] = useState("");
  const [showNewCategoryModal, setShowNewCategoryModal] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState(new Set());

  const toggleCategory = (category) => {
    setExpandedCategories(prev => {
      const newSet = new Set(prev);
      if (newSet.has(category)) {
        newSet.delete(category);
      } else {
        newSet.add(category);
      }
      return newSet;
    });
  };

  const getCategories = async () => {
    try {
      // Create category mapping
      const mapping = categories.reduce((acc, category) => {
        acc[category.id] = category.name;
        return acc;
      }, {});
      setCategoryMap(mapping);
    } catch (err) {
      console.error("Error fetching categories:", err);
      setNotification({ message: "Error fetching categories", type: 'error' });
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  //useEffect(() => {
  //  console.log(categories)
  //}, [categories]);

  const handleInputChange = (id, field, value) => {
    setSops(sops.map(sop => {
      if (sop.id === id) {
        if (field.includes('acf.')) {
          const acfField = field.split('.')[1];
          return { ...sop, acf: { ...sop.acf, [acfField]: value } };
        } else if (field === 'title.rendered') {
          return { ...sop, title: { ...sop.title, rendered: value } };
        }
        return { ...sop, [field]: value };
      }
      return sop;
    }));
  };

  const saveSOP = async (sop) => {
    const token = localStorage.getItem("token");

    try {
      const data = {
        title: sop?.title?.rendered || "Untitled SOP",
        acf: sop.acf,
        status: "publish",
        categories: sop.categories || []
      };

      if (sop.id) {
        await axios.put(
          `https://api.crm.sugarprojects.com/wp-json/wp/v2/sops/${sop.id}`,
          data,
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } else {
        const response = await axios.post(
          'https://api.crm.sugarprojects.com/wp-json/wp/v2/sops',
          data,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        sop.id = response.data.id;
      }
      setNotification({ message: `SOP "${sop.acf.title}" saved successfully!`, type: 'success' });
    } catch (error) {
      console.error('Error saving SOP:', error);
      setNotification({ message: 'Error saving SOP', type: 'error' });
    }
  };

  const saveAllSOPs = async () => {
    setIsSaving(true);
    try {
      await Promise.all(sops.map(saveSOP));
      setNotification({ message: "All SOPs saved successfully!", type: "success" });
    } catch (error) {
      console.error('Error saving all SOPs:', error);
      setNotification({ message: 'Error saving all SOPs', type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };

  const createCategory = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        'https://api.crm.sugarprojects.com/wp-json/wp/v2/categories',
        {
          name: newCategoryName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setNewCategoryName("");
      setShowNewCategoryModal(false);
      setNotification({ message: "Category created successfully!", type: 'success' });
      refreshData()
    } catch (err) {
      console.error("Error creating category:", err);
      setNotification({ message: "Error creating category", type: 'error' });
    }
  };
  

  const addNewSOP = (categoryId = null) => {
    const newSOP = {
      acf: {
        title: '',
        date_added: new Date().toISOString().split('T')[0],
        instructions: '',
      },
      categories: categoryId ? [categoryId] : [],
    };
    setSops([newSOP, ...sops]);
  };

  const confirmDeleteSOP = async () => {
    const sop = sopToDelete;
    const token = localStorage.getItem("token");

    try {
      if (sop.id) {
        await axios.delete(
          `https://api.crm.sugarprojects.com/wp-json/wp/v2/sops/${sop.id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }
      setSops(sops.filter(item => item.id !== sop.id));
      setNotification({ message: `SOP "${sop.acf.title}" deleted successfully!`, type: 'success' });
    } catch (error) {
      console.error('Error deleting SOP:', error);
      setNotification({ message: 'Error deleting SOP', type: 'error' });
    } finally {
      setShowDeleteModal(false);
      setSopToDelete(null);
    }
  };

  const getSopsByCategory = () => {
    // Initialize with all categories, including empty ones
    const categorizedSops = categories.reduce((acc, category) => {
      acc[category.name] = [];
      return acc;
    }, {});
  
  
    // Then populate with SOPs
    sops.forEach(sop => {
      if (sop.categories && sop.categories[0]) {
        const categoryName = categoryMap[sop.categories[0]];
        if (categoryName && categorizedSops[categoryName]) {
          categorizedSops[categoryName].push(sop);
        }
      }
    });
  
    return categorizedSops;
  };
  
  const categorizedSops = getSopsByCategory();
  const filteredCategories = Object.entries(categorizedSops)
    .map(([category, sops]) => [
      category,
      sops.filter(sop =>
        searchTerm === "" || 
        Object.values(sop.acf || {}).some(value =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    ]);

  return (
    <div className="bg-white shadow sm:rounded-lg p-6">
      {/* Notifications */}
      {notification && (
        <div className="pointer-events-none fixed top-12 right-0 w-full flex items-end px-4 py-6 sm:items-start sm:p-6 z-10">
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            <div className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 ${notification.type === 'error' ? 'text-red-600' : 'text-green-600'}`}>
              <div className="p-4">
                <p className="text-sm font-medium">{notification.message}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Header */}
      <div className="bg-white">
        <div className="flex flex-wrap justify-between sm:flex-nowrap mb-4">
          <h1 className="text-2xl font-semibold text-gray-900">Standard Operating Procedures</h1>
          <div className="flex-shrink-0 space-x-4">
            <button 
              onClick={() => setShowNewCategoryModal(true)}
              className="p-2 text-pink-600 hover:text-pink-500"
            >
              Add Category
            </button>
            <button
              onClick={saveAllSOPs}
              disabled={isSaving}
              className="p-2 bg-pink-600 hover:bg-pink-500 text-white rounded shadow"
            >
              {isSaving ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </div>
      </div>

      {/* Categorized SOPs */}
      <div className="space-y-4">
        {filteredCategories.map(([category, categorySOPs]) => (
          <div key={category} className="border rounded-lg bg-gray-100">
            <div 
              onClick={() => toggleCategory(category)}
              className="flex justify-between items-center p-4 cursor-pointer hover:bg-gray-50"
            >
              <span className="font-semibold">{category}</span>
              <div className="flex items-center space-x-4">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    addNewSOP(categories.find(c => c.name === category)?.id);
                  }}
                  className="text-pink-600 hover:text-pink-500"
                >
                  Add SOP
                </button>
                <span className="transform transition-transform duration-200" style={{
                  transform: expandedCategories.has(category) ? 'rotate(180deg)' : 'rotate(0deg)'
                }}>
                  ▼
                </span>
              </div>
            </div>
            
            {expandedCategories.has(category) && (
              <div className="space-y-4">
                {categorySOPs.map((sop, index) => (
                  <div key={index} className="p-4">
                    <div className="flex justify-end items-center mb-0">
                      <button
                        onClick={() => {
                          setSopToDelete(sop);
                          setShowDeleteModal(true);
                        }}
                        className="text-red-600 hover:text-red-800"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-5 h-5 inline"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                        </svg>
                      </button>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <label className="col-span-1">
                        Title
                        <input
                          type="text"
                          value={sop?.title?.rendered || ''}
                          onChange={(e) => handleInputChange(sop.id, 'title.rendered', e.target.value)}
                          className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                        />
                      </label>
                      <label className="col-span-1">
                        Date Added
                        <input
                          type="date"
                          value={sop.acf.date_added || ''}
                          onChange={(e) => handleInputChange(sop.id, 'acf.date_added', e.target.value)}
                          className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                        />
                      </label>
                      <label className="col-span-2">
                        Instructions
                        <textarea
                          value={sop.acf.instructions || ''}
                          onChange={(e) => handleInputChange(sop.id, 'acf.instructions', e.target.value)}
                          className="mt-2 h-64 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                        />
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* New Category Modal */}
      {showNewCategoryModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-lg max-w-sm mx-auto">
            <h3 className="text-lg font-semibold mb-4">Add New Category</h3>
            <input
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              placeholder="Category Name"
              className="w-full border-gray-300 rounded-md shadow-sm focus:border-pink-500 focus:ring-pink-500 mb-4"
            />
            <div className="flex justify-end space-x-2">
            <button
                onClick={() => setShowNewCategoryModal(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={createCategory}
                className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-500"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete SOP Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-lg text-center max-w-sm mx-auto">
            <h3 className="text-lg font-semibold">Are you sure?</h3>
            <p className="my-4">Do you really want to delete "{sopToDelete?.acf.title}"?</p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={confirmDeleteSOP}
                className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-500"
              >
                Yes, delete
              </button>
              <button
                onClick={() => {
                  setShowDeleteModal(false);
                  setSopToDelete(null);
                }}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default SOPsPage;