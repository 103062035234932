import React, { useState, useEffect } from "react";
import { useData } from "./DataContext";
import axios from "axios";

const ToDoList = ({ job }) => {
    const { refreshData } = useData();
    const [newTask, setNewTask] = useState("");
    const [todos, setTodos] = useState(job?.acf?.to_do_list || []);

    useEffect(() => {
        setTodos(job?.acf?.to_do_list || [])
        console.log(todos);
    }, [job])

    const handleAddTask = async () => {
        if (!newTask.trim()) return;
        
        const updatedTodos = [...todos, { task: newTask, done: false }];
        
        try {
            const token = localStorage.getItem('token');
            await axios.post(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/jobs/${job.id}`,
                {
                    acf: {
                        ...job.acf,
                        to_do_list: updatedTodos
                    }
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            
            setTodos(updatedTodos);
            setNewTask("");
            refreshData("jobs");
        } catch (err) {
            console.error("Error adding task:", err);
        }
    };

    const handleToggleTask = async (index) => {
        const updatedTodos = todos.map((todo, i) => 
            i === index ? { ...todo, done: !todo.done } : todo
        );
        
        try {
            const token = localStorage.getItem('token');
            await axios.post(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/jobs/${job.id}`,
                {
                    acf: {
                        ...job.acf,
                        to_do_list: updatedTodos
                    }
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            
            setTodos(updatedTodos);
            refreshData("jobs");
        } catch (err) {
            console.error("Error toggling task:", err);
        }
    };

    const handleDeleteTask = async (index) => {
        const updatedTodos = todos.filter((_, i) => i !== index);
        
        try {
            const token = localStorage.getItem('token');
            await axios.post(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/jobs/${job.id}`,
                {
                    acf: {
                        ...job.acf,
                        to_do_list: updatedTodos
                    }
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            
            setTodos(updatedTodos);
            refreshData("jobs");
        } catch (err) {
            console.error("Error deleting task:", err);
        }
    };

    return (
        <div className="p-4">
            <div className="flex gap-2 mb-4">
                <input
                    type="text"
                    value={newTask}
                    onChange={(e) => setNewTask(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleAddTask()}
                    className="flex-1 p-2 border-gray-300 rounded focus:border-pink-600 focus:ring-white"
                    placeholder="Add new task..."
                />
                <button
                    onClick={handleAddTask}
                    className="px-4 py-2 bg-pink-600 text-white rounded hover:bg-pink-500"
                >
                    Add
                </button>
            </div>
            
            <ul className="space-y-2">
                {todos.map((todo, index) => (
                    <li key={index} className="flex items-center gap-2 p-2 border rounded">
                        <input
                            type="checkbox"
                            checked={todo.done}
                            onChange={() => handleToggleTask(index)}
                            className="rounded border-gray-300 text-pink-600 focus:ring-pink-500"
                        />
                        <span className={"flex-1"}>
                            {todo.task}
                        </span>
                        <button
                            onClick={() => handleDeleteTask(index)}
                            className="px-2 py-1 text-red-500 hover:text-red-700"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 inline"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                />
                            </svg>
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ToDoList;