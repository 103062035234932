import React, { useState, useMemo } from "react";
import { ChevronDown, ChevronRight } from "lucide-react";

const MaintenanceHistory = ({ website }) => {
  const [expandedYear, setExpandedYear] = useState(null);
  const [expandedMonths, setExpandedMonths] = useState({});
  const [expandedRows, setExpandedRows] = useState({});

  // Format date function
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
  };

  // Group logs by year and month
  const groupedLogs = useMemo(() => {
    const logs = website.acf.maintenance_logs || [];
    return logs.reduce((acc, log) => {
      const date = new Date(log.date);
      const year = date.getFullYear();
      const month = date.getMonth();

      if (!acc[year]) {
        acc[year] = {};
      }
      if (!acc[year][month]) {
        acc[year][month] = [];
      }
      acc[year][month].push(log);

      return acc;
    }, {});
  }, [website.acf.maintenance_logs]);

  const sortedYears = useMemo(() => {
    return Object.keys(groupedLogs).sort((a, b) => b - a);
  }, [groupedLogs]);

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const toggleYear = (year) => {
    setExpandedYear(expandedYear === year ? null : year);
  };

  const toggleMonth = (year, month) => {
    setExpandedMonths(prev => ({
      ...prev,
      [`${year}-${month}`]: !prev[`${year}-${month}`]
    }));
  };

  const toggleRowExpansion = (year, month, index) => {
    setExpandedRows(prev => ({
      ...prev,
      [`${year}-${month}-${index}`]: !prev[`${year}-${month}-${index}`]
    }));
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md mt-8">
      <h2 className="text-xl font-semibold mb-6">Maintenance History</h2>
      
      <div className="space-y-4">
        {sortedYears.map(year => (
          <div key={year} className="border rounded-lg overflow-hidden">
            <div
              onClick={() => toggleYear(year)}
              className="bg-gray-100 p-4 flex items-center justify-between cursor-pointer hover:bg-gray-200"
            >
              <h3 className="text-xl font-semibold text-gray-800">{year}</h3>
              {expandedYear === year ? (
                <ChevronDown className="w-5 h-5 text-gray-600" />
              ) : (
                <ChevronRight className="w-5 h-5 text-gray-600" />
              )}
            </div>

            {expandedYear === year && (
              <div className="divide-y">
                {months.map((monthName, monthIndex) => {
                  const monthLogs = groupedLogs[year][monthIndex];
                  if (!monthLogs?.length) return null;

                  return (
                    <div key={`${year}-${monthIndex}`} className="bg-white">
                      <div
                        onClick={() => toggleMonth(year, monthIndex)}
                        className="p-3 bg-gray-50 flex items-center cursor-pointer hover:bg-gray-100"
                      >
                        <div className="flex items-center">
                          {expandedMonths[`${year}-${monthIndex}`] ? (
                            <ChevronDown className="w-4 h-4 text-gray-600 mr-2" />
                          ) : (
                            <ChevronRight className="w-4 h-4 text-gray-600 mr-2" />
                          )}
                          <h4 className="text-lg font-medium text-gray-700">{monthName}</h4>
                          <span className="ml-2 text-sm text-gray-500">
                            ({monthLogs.length} {monthLogs.length === 1 ? 'entry' : 'entries'})
                          </span>
                        </div>
                      </div>

                      {expandedMonths[`${year}-${monthIndex}`] && (
                        <div className="overflow-x-auto">
                          <table className="min-w-full">
                            <thead className="bg-gray-50">
                              <tr>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Date</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">User</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Start Time</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">End Time</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Details</th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                              {monthLogs.map((log, index) => (
                                <React.Fragment key={index}>
                                  <tr
                                    onClick={() => toggleRowExpansion(year, monthIndex, index)}
                                    className="cursor-pointer hover:bg-gray-50"
                                  >
                                    <td className="px-4 py-2 text-sm text-gray-900">{formatDate(log.date)}</td>
                                    <td className="px-4 py-2 text-sm text-gray-900">{log.user}</td>
                                    <td className="px-4 py-2 text-sm text-gray-900">{log.start_time}</td>
                                    <td className="px-4 py-2 text-sm text-gray-900">{log.end_time}</td>
                                    <td className="px-4 py-2 text-sm text-gray-900 truncate">
                                      {log.details.slice(0, 20)}...
                                    </td>
                                  </tr>
                                  {expandedRows[`${year}-${monthIndex}-${index}`] && (
                                    <tr>
                                      <td colSpan="5" className="px-4 py-2 bg-gray-50">
                                        <div dangerouslySetInnerHTML={{ __html: log.details }} />
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ))}
      </div>

      {sortedYears.length === 0 && (
        <div className="text-center text-gray-500 py-8">
          No maintenance logs found.
        </div>
      )}
    </div>
  );
};

export default MaintenanceHistory;