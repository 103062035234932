import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import WebsiteListComponent from "./WebsiteListComponent";
import ContactListComponent from "./ContactListComponent";
import NotesComponent from "./NotesComponent";
import WebsiteDrawer from "./WebsiteDrawer";
import { useData } from "./DataContext";
import { TrashIcon } from "@heroicons/react/20/solid";
import { decode } from 'html-entities';

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, isDeleting }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full">
        <h2 className="text-xl font-semibold mb-4">Are you absolutely sure?</h2>
        <p className="text-gray-600 mb-6">
          This will permanently delete the client and all associated websites. 
          This action cannot be undone.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
            disabled={isDeleting}
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting...' : 'Delete Permanently'}
          </button>
        </div>
      </div>
    </div>
  );
};

const ClientPage = ({ clientId }) => {
  const {
    clients,
    websites,
    fetchData,
    addWebsite,
    deleteWebsite,
    addNote,
    refreshData,
    users
  } = useData();

  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [userImages, setUserImages] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // Fetch user images only when needed
  useEffect(() => {
    const fetchUserImages = async () => {
      try {
        const images = {};
        users.forEach(user => {
          if (user.acf && user.acf.user_image) {
            const firstName = user.name.split(" ")[0];
            images[firstName] = user.acf.user_image;
          }
        });
        setUserImages(images);
      } catch (error) {
        console.error("Error fetching user images:", error);
      }
    };
    fetchUserImages();
  }, []);

  // UseEffect to fetch client data if not already fetched
  useEffect(() => {
    if (!clients || !clients.length) {
      fetchData();
    }
  }, [fetchData, clients]);

  // Find the client by ID
  const client = clients.find(client => client.id === parseInt(clientId));

  // If client not found, show loading
  if (!client) {
    return <p>Loading client data...</p>;
  }

  // Handler for deleting a client and all associated websites
  const handleDeleteClient = async () => {
    setIsDeleting(true);
    try {
      const token = localStorage.getItem('token');

      // First, delete all websites associated with this client
      const clientWebsites = websites?.filter(website => 
        client?.acf?.websites?.includes(website.id)
      ) || [];

      // Delete each website
      for (const website of clientWebsites) {
        await axios.delete(
          `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${website.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      // Then delete the client
      await axios.delete(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/clients/${client.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Refresh data or navigate away
      refreshData("clients");
      refreshData("websites");
      navigate('/clients'); // Navigate back to clients list

    } catch (error) {
      console.error('Error deleting client:', error);
      // Show error message to user
      alert('Failed to delete client. Please try again.');
    } finally {
      setIsDeleting(false);
      setIsDeleteModalOpen(false);
    }
  };

  const handleAddNote = async (noteText) => {
    addNote(client.id, "client", noteText);
  };

  const handleAddWebsite = async (websiteData) => {
    try {
      console.log(websiteData, client.id);
      const success = await addWebsite(websiteData, client.id);
      refreshData();
      if (success) setIsDrawerOpen(false);
    } catch (error) {
      // Handle any unexpected errors
      console.error("Failed to add website", error);
    }
  };

  const handleDeleteWebsite = (websiteId) => {
    deleteWebsite(websiteId, client.id);
  };

  // Filter websites related to the selected client
  const clientWebsites = websites?.filter(website =>
    client?.acf?.websites?.includes(website.id)
  ) || [];

  return (
    <div className="relative">
      {/* Delete Client Button */}
      <button 
        onClick={() => setIsDeleteModalOpen(true)}
        className="absolute top-0 right-0 text-gray-400 hover:text-pink-600"
        aria-label="Delete client"
      >
        <TrashIcon className="h-6 w-6" />
      </button>

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal 
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteClient}
        isDeleting={isDeleting}
      />

      <h2 className="text-2xl font-semibold">{decode(client?.title?.rendered)}</h2>
      <p>{client?.content?.rendered}</p>

      {/* Rest of the component remains the same */}
      <div className="flex justify-between mb-6">
        <h3 className="text-xl font-semibold text-gray-900 mt-6">Websites:</h3>
        <div className="ml-4 mt-4 flex-shrink-0">
          <button
            type="button"
            onClick={() => setIsDrawerOpen(true)}
            className="relative inline-flex items-center rounded-md bg-pink-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500"
          >
            Add website
          </button>
        </div>
      </div>

      <WebsiteListComponent
        websites={clientWebsites}
        showSearchBar={false}
        delete={true}
        onDelete={handleDeleteWebsite}
      />

      <h3 className="text-xl font-semibold text-gray-900 mt-6">Contacts:</h3>
      <ContactListComponent websites={clientWebsites} unique={true} cols={2} />

      <h3 className="text-xl font-semibold text-gray-900 mt-6">Notes:</h3>
      <NotesComponent 
        notes={client.acf.notes || []} 
        handleAddNote={handleAddNote} 
        userImages={userImages} 
      />

      <WebsiteDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onSubmit={handleAddWebsite}
        client={client.id}
      />
    </div>
  );
};

export default ClientPage;