import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { format, differenceInDays, parseISO, isToday, parse } from 'date-fns';
import { useData } from './DataContext';

const ReminderContext = createContext();

export const ReminderProvider = ({ children }) => {
  const [chargeReminders, setChargeReminders] = useState([]);
  const [eventReminders, setEventReminders] = useState([]);
  const [leadReminders, setLeadReminders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { leads, events } = useData();

  // Helper function to safely format time
  const formatTimeString = (timeStr) => {
    if (!timeStr) return 'all day';
    
    try {
      // Handle various time formats
      if (timeStr.includes('T')) {
        // If time is in ISO format
        return format(new Date(timeStr), 'h:mm a');
      } else {
        // If time is in HH:mm:ss format
        const [hours, minutes] = timeStr.split(':');
        const date = new Date();
        date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0);
        return format(date, 'h:mm a');
      }
    } catch (error) {
      console.error('Error formatting time:', error, timeStr);
      return 'all day';
    }
  };

  const fetchChargeReminders = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      
      const websitesResponse = await axios.get(
        'https://api.crm.sugarprojects.com/wp-json/wp/v2/websites?per_page=100',
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      const activeReminders = websitesResponse.data.flatMap(website => {
        return (website.acf.charges || [])
          .filter(charge => charge.reminder === true && charge.end_date)
          .map(charge => ({
            type: 'charge',
            websiteId: website.id,
            websiteName: website.title.rendered,
            chargeName: charge.charge,
            endDate: charge.end_date,
            daysRemaining: differenceInDays(parseISO(charge.end_date), new Date())
          }))
          .filter(reminder => reminder.daysRemaining <= 30 && reminder.daysRemaining > 0)
      });

      setChargeReminders(activeReminders);
    } catch (error) {
      console.error('Error fetching charge reminders:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const processEventReminders = () => {
    if (!events) return;

    try {
      const now = new Date();
      const todayEvents = events.filter(event => {
        try {
          const eventDate = event.acf?.date;
          return eventDate && isToday(parseISO(eventDate));
        } catch (error) {
          console.error('Error processing event date:', error, event);
          return false;
        }
      }).map(event => {
        const startTime = event.acf?.start_time;
        return {
          type: 'event',
          id: event.id,
          title: event.title?.rendered || 'Untitled Event',
          date: event.acf?.date,
          startTime: formatTimeString(startTime),
          raw: event
        };
      });

      setEventReminders(todayEvents);
    } catch (error) {
      console.error('Error processing event reminders:', error);
    }
  };

  const processLeadReminders = () => {
    if (!leads) return;

    try {
      const leadReminders = leads.map(lead => ({
        type: 'lead',
        id: lead.id,
        name: lead?.title?.rendered,
        dateCreated: lead?.acf?.last_contact,
      }));

      setLeadReminders(leadReminders);
    } catch (error) {
      console.error('Error processing lead reminders:', error);
    }
  };

  const dismissReminder = (type, id, additionalId = '', index = -1) => {
    const dismissalDate = new Date().toISOString();
    const dismissalKey = 
      type === 'charge' ? `charge-${id}-${additionalId}-${index}` : 
      type === 'event' ? `event-${id}` : 
      `lead-${id}`;

    // Store the dismissal with the current date in localStorage
    const dismissedReminders = JSON.parse(localStorage.getItem('dismissedReminders')) || {};
    dismissedReminders[dismissalKey] = dismissalDate;
    localStorage.setItem('dismissedReminders', JSON.stringify(dismissedReminders));
    
    // Remove from the appropriate state
    if (type === 'charge') {
      setChargeReminders(current => 
        current.filter((reminder, idx) => 
          !(reminder.websiteId === id && reminder.chargeName === additionalId && idx === index)
        )
      );
    } else if (type === 'event') {
      setEventReminders(current => 
        current.filter(reminder => reminder.id !== id)
      );
    } else if (type === 'lead') {
      setLeadReminders(current =>
        current.filter(reminder => reminder.id !== id)
      );
    }
  };
  

  // Clear dismissals that are older than 60 days
  const clearExpiredDismissals = () => {
    const dismissedReminders = JSON.parse(localStorage.getItem('dismissedReminders')) || {};
    const now = new Date();

    for (const [key, value] of Object.entries(dismissedReminders)) {
      const dismissalDate = new Date(value);
      const daysSinceDismissed = differenceInDays(now, dismissalDate);

      if (daysSinceDismissed > 60) {
        delete dismissedReminders[key];
      }
    }

    localStorage.setItem('dismissedReminders', JSON.stringify(dismissedReminders));
  };

  // Load dismissed reminders and filter out those expired
  useEffect(() => {
    clearExpiredDismissals();
    fetchChargeReminders();
  }, []);

  // Process event reminders when events change
  useEffect(() => {
    processEventReminders();
  }, [events]);

  useEffect(() => {
    processLeadReminders();
  }, [leads]);

  const filteredReminders = [
    ...chargeReminders.filter((reminder, index) => {
      const dismissedReminders = JSON.parse(localStorage.getItem('dismissedReminders')) || {};
      return !dismissedReminders[`charge-${reminder.websiteId}-${reminder.chargeName}-${index}`];
    }),
    ...eventReminders.filter(reminder => {
      const dismissedReminders = JSON.parse(localStorage.getItem('dismissedReminders')) || {};
      return !dismissedReminders[`event-${reminder.id}`];
    }),
    ...leadReminders.filter(reminder => {
      const dismissedReminders = JSON.parse(localStorage.getItem('dismissedReminders')) || {};
      return !dismissedReminders[`lead-${reminder.id}`];
    }),
  ];

  return (
    <ReminderContext.Provider value={{ 
      reminders: filteredReminders, 
      isLoading, 
      fetchChargeReminders, 
      dismissReminder 
    }}>
      {children}
    </ReminderContext.Provider>
  );
};

export const ReminderNotifications = () => {
  const { reminders, dismissReminder } = useReminders();

  const decodeHtmlEntities = (str) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = str;
    return txt.value;
  };

  const renderReminderContent = (reminder) => {
    if (reminder.type === 'charge') {
      return (
        <>
          <div className="font-bold">{reminder.websiteName}</div>
          <div className="text-sm text-gray-600">
            {reminder.chargeName} ends in {reminder.daysRemaining} days
          </div>
        </>
      );
    } else if (reminder.type === 'event') {
      return (
        <>
          <div className="font-bold">{reminder.title}</div>
          <div className="text-sm text-gray-600">
            Today at {reminder.startTime}
          </div>
        </>
      );
    } else if (reminder.type === 'lead') {
      return (
        <>
          <div className="font-bold">New Lead: {decodeHtmlEntities(reminder.name)}</div>
          <div className="text-sm text-gray-600">
            Contact on {format(new Date(reminder.dateCreated), 'MMM dd, yyyy')}
          </div>
        </>
      );
    }
  };
  

  return (
    <div className="absolute right-0 top-full mt-2 w-80 bg-white shadow-lg rounded-md border">
      {reminders.length === 0 ? (
        <div className="p-4 text-gray-500 text-center">
          No upcoming reminders
        </div>
      ) : (
        <ul>
          {reminders.map((reminder, index) => (
            <li 
              key={reminder.type === 'charge' ? 
                `charge-${reminder.websiteId}-${index}` : 
                reminder.type === 'event' ? 
                `event-${reminder.id}` : 
                `lead-${reminder.id}`}
              className="p-4 border-b hover:bg-gray-50 flex justify-between items-center"
            >
              <div>
                {renderReminderContent(reminder)}
              </div>
              <button 
                onClick={() => dismissReminder(reminder.type, reminder.type === 'charge' ? 
                  reminder.websiteId : reminder.id, reminder.chargeName || '', index)}
                className="text-pink-600 hover:text-pink-800"
              >
                Dismiss
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export const useReminders = () => {
  const context = useContext(ReminderContext);
  if (!context) {
    throw new Error('useReminders must be used within a ReminderProvider');
  }
  return context;
};

export default ReminderProvider;