import React, { useEffect, useState } from 'react';
import JobDrawer from './JobDrawer';
import { useData } from './DataContext';
import { Link } from "react-router-dom";

const JobsPage = () => {
  // State to store jobs and websites data
  const { websites, setWebsites, jobs, setJobs } = useData();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // State for filter and search
  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  // Helper function to calculate total hours for a job
  const calculateTotalHours = (logs) => {
    if (!logs || !Array.isArray(logs)) return 0;
    
    return logs.reduce((total, log) => {
      // Check if start_time and end_time are valid
      if (log.start_time && log.end_time) {
        const startTime = new Date(`1970-01-01T${log.start_time}:00`);
        const endTime = new Date(`1970-01-01T${log.end_time}:00`);
        const hours = (endTime - startTime) / (1000 * 60 * 60);
        return total + (hours > 0 ? hours : 0);
      }
      return total;
    }, 0);
  };

  // Helper function to calculate progress percentage
  const calculateProgressPercentage = (logs, targetHours) => {
    const totalHours = calculateTotalHours(logs);
    const target = Number(targetHours) || 40; // Default to 40 if not specified
    return Math.min((totalHours / target) * 100, 100);
  };

  // Filtered jobs based on status and search query
  const filteredJobs = jobs.filter((job) => {
    const jobTitle = job.title.rendered.toLowerCase();
    const website = websites.find((site) => site.id === job.acf?.website?.[0]);
    const websiteTitle = website ? website.title.rendered.toLowerCase() : "";

    const matchesStatus = !statusFilter || job.acf.status === statusFilter;
    const matchesSearch = jobTitle.startsWith(searchQuery.toLowerCase()) || websiteTitle.startsWith(searchQuery.toLowerCase());

    return matchesStatus && matchesSearch;
  });

  // Function to handle adding a new job
  const handleAddJob = async (newJob) => {
    try {
      const token = localStorage.getItem("token");

      const jobData = {
        title: newJob.name,
        status: "publish",
        acf: {
          website: [newJob.website],
          target_hours: "40" // Default target hours
        }
      };

      const response = await fetch('https://api.crm.sugarprojects.com/wp-json/wp/v2/jobs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(jobData),
      });

      if (response.ok) {
        const createdJob = await response.json();
        setJobs((prevJobs) => [createdJob, ...prevJobs]); 
      } else {
        console.error('Failed to add job:', await response.text());
      }
    } catch (error) {
      console.error('Error adding job:', error);
    }
  };

  return (
    <div className="bg-white shadow sm:rounded-lg p-0">
      <div className="flex justify-between items-center p-6">
      <h1 className="text-2xl font-semibold leading-6 text-gray-900">Jobs</h1>
        <button
          onClick={() => setIsDrawerOpen(true)}
          className="rounded-md bg-pink-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500"
        >
          Add Job
        </button>
      </div>

      {/* Filter and Search Bar */}
      <div className="flex gap-4 mb-4 px-8">
        <input
          type="text"
          placeholder="Search by title or website"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="block w-5/6 rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
        />
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="block w-1/6 rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
        >
          <option value="">All</option>
          <option value="upcoming" className='text-yellow-700'>Upcoming</option>
          <option value="ongoing" className='text-blue-700'>Ongoing</option>
          <option value="completed" className='text-green-700'>Completed</option>
        </select>
      </div>

      {/* Jobs list */}
      <ul role="list" className="divide-y divide-gray-200">
        {filteredJobs.map((job) => {
          let websiteId = "";
          if (Array.isArray(job.acf.website)) {
            websiteId = job.acf?.website[0] || null;
          } else {
            websiteId = job.acf?.website || null;
          }
          
          const website = websites.find((site) => site.id === websiteId);

          // Determine status tag
          const status = job.acf.status;
          let statusTag;

          if (status === "ongoing") {
            statusTag = (
              <p className="mt-0.5 whitespace-nowrap rounded-md bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                Ongoing
              </p>
            );
          } else if (status === "upcoming") {
            statusTag = (
              <p className="mt-0.5 whitespace-nowrap rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
                Upcoming
              </p>
            );
          } else if (status === "completed") {
            statusTag = (
              <p className="mt-0.5 whitespace-nowrap rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                Completed
              </p>
            );
          } else {
            statusTag = (
              <p className="mt-0.5 whitespace-nowrap rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                Unknown
              </p>
            );
          }

          // Calculate progress
          const logs = job.acf?.logs || [];
          const targetHours = job.acf?.target_hours || 40;
          const progressPercentage = calculateProgressPercentage(logs, targetHours);

          return (
            <Link
              to={`/jobs/${job.id}`}
              key={job.id}
              className="flex items-center justify-between py-5 px-8 hover:bg-gray-50"
            >
              <div className="min-w-0 w-full">
                <div className="flex items-start justify-between">
                  <div className="flex items-center gap-x-3">
                    <p className="text-sm font-semibold text-gray-900">{job.title.rendered}</p>
                    {statusTag}
                  </div>
                </div>
                <div className="mt-1 flex items-center justify-between gap-x-2 text-xs text-gray-500">
                  <p className="whitespace-nowrap">{website ? website.title.rendered : 'N/A'}</p>
                </div>
                {/* Progress Bar */}
                <div className="mt-2 w-full">
                  <div className="relative flex items-center">
                    <div className="flex-grow mr-2">
                      <div className="overflow-hidden h-2 rounded bg-pink-200 relative">
                        <div
                          style={{ width: `${progressPercentage}%` }}
                          className="absolute top-0 left-0 h-full bg-pink-600"
                        ></div>
                      </div>
                    </div>
                    <div className="flex-shrink-0">
                      <span className="font-semibold text-pink-600">
                        {Math.round(progressPercentage)}%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </ul>

      {/* Job Drawer for adding a new job */}
      <JobDrawer 
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onSubmit={handleAddJob}
        websites={websites}
      />
    </div>
  );
};

export default JobsPage;