import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useData } from './DataContext';

const WordpressComponent = ({ externalSiteApiLink, website }) => {
    const [wordpressVersion, setWordpressVersion] = useState();
    const [yoothemeVersion, setYoothemeVersion] = useState();

    const { decodeHtmlEntities } = useData()

const fetchPlugins = async () => {
    let wordpressVersion = null;
    let yoothemeVersion = null;

    // Fetch WordPress version
    try {
        const wpResponse = await fetch(`${externalSiteApiLink}/wp-json/custom/v1/version`);
        if (!wpResponse.ok) {
            throw new Error(`HTTP error! status: ${wpResponse.status}`);
        }
        const data = await wpResponse.json();
        console.log("Wordpress:", data);
        wordpressVersion = data.version;
        setWordpressVersion(data.version);
    } catch (error) {
        console.error('Error fetching Wordpress version:', error);
    }

    // Fetch Yootheme version
    try {
        const ytResponse = await fetch(`${externalSiteApiLink}/wp-json/custom/v1/yootheme-version`);
        if (!ytResponse.ok) {
            throw new Error(`HTTP error! status: ${ytResponse.status}`);
        }
        const data2 = await ytResponse.json();
        console.log("Yootheme:", data2);
        yoothemeVersion = data2.version;
        setYoothemeVersion(data2.version);
    } catch (error) {
        console.error('Error fetching Yootheme version:', error);
    }

    // Fetch plugins
    try {
        // Direct access to the public CPT endpoint
        const response = await axios.get(`${externalSiteApiLink}/wp-json/wp/v2/plugin_info`, {
            params: {
                per_page: 100,
                _fields: 'id,title,meta'
            }
        });

        console.log(response.data);

        // Transform the response to match our expected format
        const plugins = response.data.map(plugin => ({
            name: plugin.title.rendered,
            version: plugin.meta._plugin_version,
            author: plugin.meta._plugin_author,
            authorUri: plugin.meta._plugin_author_uri,
            pluginUri: plugin.meta._plugin_uri,
            isActive: plugin.meta._plugin_active === '1'
        }));

        return plugins;
    } catch (error) {
        console.error('Error fetching plugins:', error);
        return [];
    }
};


    const [plugins, setPlugins] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadPlugins = async () => {
            setIsLoading(true);
            try {
                const pluginData = await fetchPlugins();
                setPlugins(pluginData);
                setError(null);
            } catch (err) {
                setError('Failed to load plugins');
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };

        loadPlugins();
    }, [externalSiteApiLink]);

    const handleUpdatePlugins = async () => {
        try {
            const externalPlugins = await fetchPlugins();
            const formattedPlugins = externalPlugins.map((plugin) => ({
                name: plugin.name,
                version: plugin.version,
            }));
    
            const token = localStorage.getItem('token');
    
            await axios.post(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${website.id}`,
                {
                    acf: {
                        plugins: formattedPlugins,
                    },
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            setPlugins(externalPlugins);
        } catch (error) {
            console.error('Error updating plugins:', error);
            alert('Failed to update plugins.');
        }
    };

    if (isLoading) {
        return <div className="text-gray-500">Loading plugins...</div>;
    }

    if (error) {
        return <div className="text-red-500">{error}</div>;
    }

    return (
        <div className="">
            <div className='flex justify-between align-middle mb-4'>
                <div>
                    <p>Wordpress Version: {wordpressVersion}</p>
                    <p>Yootheme Version: {yoothemeVersion}</p>
                </div>
                <button
                    className="rounded-md bg-pink-600 px-3 py-2 text-sm font-semibold text-white hover:bg-pink-500"
                    onClick={handleUpdatePlugins}
                >
                    Update (Don't Click)
                </button>
            </div>
            <ul className="space-y-4 max-h-[400px] overflow-y-scroll">
                {plugins.length > 0 ? (
                    plugins.map((plugin, index) => (
                        <li
                            key={index}
                            className="p-4 border border-gray-200 rounded-lg shadow-sm flex justify-between items-center bg-gray-50"
                        >
                            <div className="flex flex-col">
                                <div className="text-md font-medium text-gray-700">
                                    {decodeHtmlEntities(plugin.name)}
                                    {plugin.isActive && (
                                        <span className="ml-2 px-2 py-1 text-xs bg-green-100 text-green-800 rounded-full">
                                            Active
                                        </span>
                                    )}
                                </div>
                                {plugin.author && (
                                    <div className="text-sm text-gray-500">
                                        by{' '}
                                        {plugin.authorUri ? (
                                            <a href={plugin.authorUri} target="_blank" rel="noopener noreferrer" className="text-pink-600 hover:text-pink-800">
                                                {decodeHtmlEntities(plugin.author)}
                                            </a>
                                        ) : (
                                            decodeHtmlEntities(plugin.author)
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="text-sm text-gray-500">Version {plugin.version}</div>
                        </li>
                    ))
                ) : (
                    <li className="text-gray-500">No plugins found.</li>
                )}
            </ul>
        </div>
    );
};

export default WordpressComponent;
