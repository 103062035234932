import React, { useState, useEffect } from 'react';
import { useData } from './DataContext';
import { Calendar, X } from 'lucide-react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import axios from 'axios';

const AnnualLeaveCalendar = () => {
  const username = localStorage.getItem("username");
  const { users, refreshData, officeClosed, events } = useData();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDates, setSelectedDates] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showClosedDays, setShowClosedDays] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [e, setE] = useState([]);
  const [eventId, setEventId] = useState([]);
  const [currentUser, setCurrentUser] = useState(users.find(u => u.slug.toLowerCase() === username.toLowerCase()));
  const [isOpen, setIsOpen] = useState(false);
  const [remainingDays, setRemainingDays] = useState(20);
  const [showDeleteEventModal, setShowDeleteEventModal] = useState(false);
  const [updatingAssignments, setUpdatingAssignments] = useState({});

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

    useEffect(() => {
        setCurrentUser(users.find(u => u.slug.toLowerCase() === username.toLowerCase()));
    }, [currentUser, users]);

    useEffect(() => {
      if (selectedDates && selectedDates.length > 0) {
        const sortedDates = [...selectedDates].sort((a, b) => new Date(a) - new Date(b));
        
        // Only update the state if the dates are not already sorted
        if (JSON.stringify(sortedDates) !== JSON.stringify(selectedDates)) {
          setSelectedDates(sortedDates);
        }
      }
    }, [selectedDates]);
    
    

    useEffect(() => {
        if (currentUser?.acf?.holiday_reset && currentUser?.acf?.al_requests) {
            const holidayResetDate = new Date(currentUser.acf.holiday_reset);
            const startDate = new Date(holidayResetDate);
            startDate.setDate(startDate.getDate() - 365);
    
            //console.log(currentUser.acf.al_requests)
            //console.log(startDate)
    
            const alRequestsInPeriod = currentUser.acf.al_requests.filter(req => {
                const requestDate = new Date(req.al_date); // Ensure parsing the date from string
                return requestDate >= startDate && requestDate <= holidayResetDate;
            }).length;
    
            setRemainingDays(20 - alRequestsInPeriod);
        }
    }, [currentUser]);    


  // Get days in month
  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  // Get first day of month (0 = Sunday, 1 = Monday, etc.)
  const getFirstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  // Format date to YYYY-MM-DD
  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  // Get all booked dates from users
  const getBookedDates = () => {
    const bookedDates = {};
    users.forEach(user => {
      if (user.acf.al_requests) {
        user.acf.al_requests.forEach(request => {
          const date = request.al_date;
          if (!bookedDates[date]) {
            bookedDates[date] = [];
          }
          bookedDates[date].push(user?.slug);
        });
      }
    });
    return bookedDates;
  };
  

  const handleBookDates = async () => {
    if (selectedDates.length === 0) return;
  
    try {
      console.log("curent:", currentUser);
      await setBookedDates(currentUser.id, selectedDates);
      setSelectedDates([]);
      setShowConfirmation(false);
      //alert('Annual leave days booked successfully!');
    } catch (err) {
      console.error('Error booking annual leave:', err);
    }
  };

  const bookedDates = getBookedDates();
  //console.log(bookedDates)

  //useEffect(() => {
    //console.log(selectedDates, currentUser?.acf?.al_requests)
  //}, [selectedDates])

// Handle date selection
const handleDateClick = (day) => {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    const dateStr = formatDate(date);

  
    // Toggle date selection
    if (selectedDates.includes(dateStr)) {
      setSelectedDates(selectedDates.filter(d => d !== dateStr));
    } else {
      setSelectedDates([...selectedDates, dateStr]);
    }
  };
  

// Helper function to check if a date is a weekend
const isWeekend = (date) => {
  const day = date.getDay();
  return day === 0 || day === 6; // 0 is Sunday, 6 is Saturday
};

// Helper function to check if a date is an office closed day
const isOfficeClosed = (date) => {
  const dateStr = formatDate(date);
  return officeClosed.some(holiday => holiday.acf.date === dateStr);
};

// Helper function to get office closed reason for a date
const getOfficeClosedReason = (date) => {
  const dateStr = formatDate(date);
  const closedDay = officeClosed.find(holiday => holiday.acf.date === dateStr);
  return closedDay?.acf.reason || '';
};

const generateCalendarDays = () => {
  const daysInMonth = getDaysInMonth(currentDate);
  const firstDay = getFirstDayOfMonth(currentDate);
  const days = [];

  // Helper functions remain the same...
  const formatTime = (timeStr) => {
    if (!timeStr) return '';
    return new Date(`2000-01-01T${timeStr}`).toLocaleTimeString([], {
      hour: 'numeric',
      minute: '2-digit'
    });
  };

  const getEventTitle = (event) => {
    if (typeof event.title === 'string') return event.title;
    return event.title?.rendered || 'Untitled Event';
  };

  const isUserAssignedToEvent = (eventId, user) => {
    return Boolean(user.acf?.user_events?.some(ue => ue.event_id === eventId.toString()));
  };

  // Filter events for current user
  const filterEventsForCurrentUser = (events) => {
    if (!currentUser?.acf?.user_events) return [];
    return events.filter(event => isUserAssignedToEvent(event.id, currentUser));
  };

  const handleUserAssignment = async (e, eventId, userId, isAssigned) => {
    e.stopPropagation();
    const token = localStorage.getItem("token");
    
    // Create a unique key for this event-user combination
    const assignmentKey = `${eventId}-${userId}`;
    
    // Optimistically update the UI
    setUpdatingAssignments(prev => ({
      ...prev,
      [assignmentKey]: !isAssigned
    }));
    
    try {
      const user = users.find(u => u.id === userId);
      const currentEvents = user.acf?.user_events || [];
      
      let updatedEvents;
      if (isAssigned) {
        updatedEvents = currentEvents.filter(ue => ue.event_id !== eventId.toString());
      } else {
        updatedEvents = [...currentEvents, { event_id: eventId.toString() }];
      }
  
      await axios.post(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/users/${userId}`,
        {
          acf: {
            user_events: updatedEvents
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        }
      );
  
      refreshData("users");
    } catch (err) {
      // Revert the optimistic update on error
      setUpdatingAssignments(prev => ({
        ...prev,
        [assignmentKey]: isAssigned
      }));
      console.error("Error updating user assignment:", err);
      alert("Failed to update user assignment.");
    } finally {
      // Clear the updating state after a short delay
      setTimeout(() => {
        setUpdatingAssignments(prev => {
          const newState = { ...prev };
          delete newState[assignmentKey];
          return newState;
        });
      }, 1000);
    }
  };

  // Empty cells code remains the same...
  for (let i = 0; i < firstDay; i++) {
    days.push(<div key={`empty-${i}`} className="h-12"></div>);
  }

  // Calendar cells generation
  for (let day = 1; day <= daysInMonth; day++) {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    const dateStr = formatDate(date);
    const isSelected = selectedDates.includes(dateStr);
    const isBooked = bookedDates[dateStr]?.length > 0;
    const isWeekendDay = isWeekend(date);
    const isOfficeClosedDay = isOfficeClosed(date);
    const isDisabled = isWeekendDay || isOfficeClosedDay;
    const isToday = new Date().toDateString() === date.toDateString();
    
    const officeClosedReason = isOfficeClosedDay ? getOfficeClosedReason(date) : '';
    
    const dayEvents = filterEventsForCurrentUser(
      events.filter(event => event.acf.date === dateStr)
    );
    
    days.push(
      <div
        key={day}
        className={`h-32 border border-gray-200 relative ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'} transition-colors p-1
          ${isDisabled ? 'bg-gray-100' : ''}
          ${isBooked && isSelected && !isDisabled ? 'bg-pink-50' : ''}
          ${isBooked && !isSelected && !isDisabled ? 'hover:border-pink-500 bg-gray-50 hover:border-pink-500' : ''}
          ${!isBooked && isSelected && !isDisabled ? 'bg-pink-50 hover:border-pink-500' : ''}
          ${!isBooked && !isSelected && !isDisabled ? 'hover:border-pink-500' : ''}`}
        onClick={() => !isDisabled && handleDateClick(day)}
      >
        <div className="flex flex-col h-full">
          {/* Day header remains the same... */}
          <div className="flex justify-between items-start">
            <span className={`text-sm ${isSelected ? 'text-pink-600 ' : ''}${isToday ? 'text-pink-600 font-bold' : ''}`}>
              {day}
            </span>
            {isSelected && (
              <span className="w-2 h-2 rounded-full bg-pink-600"></span>
            )}
          </div>
          
          {/* Office closed notice remains the same... */}
          {isOfficeClosedDay && (
            <div className="mt-1">
              <p className="text-[10px] leading-tight text-green-600">
                {officeClosedReason}
              </p>
            </div>
          )}
          
          {/* Events Display with updated hover menu */}
          {dayEvents.length > 0 && (
            <div className="mt-1 space-y-1 overflow-visible max-h-20">
              {dayEvents
                .sort((a, b) => {
                  const timeA = new Date(`1970-01-01T${a.acf.start_time}`);
                  const timeB = new Date(`1970-01-01T${b.acf.start_time}`);
                  return timeA - timeB;
                })
                .map((event, index) => (
                  <div
                    key={index}
                    className="bg-pink-100 rounded px-1 py-0.5 text-[10px] leading-tight group relative"
                  >
                    <div className="font-medium text-pink-800 truncate pr-4">
                      {getEventTitle(event)}
                      <button
                        onClick={(e) => {
                          setE(e);
                          setEventId(event.id);
                          setShowDeleteEventModal(true);
                        }}
                        className="absolute right-1 top-1 opacity-0 group-hover:opacity-100 transition-opacity text-pink-700 hover:text-pink-900"
                      >
                        ×
                      </button>
                    </div>
                    <div className="text-pink-600">
                      {formatTime(event.acf.start_time)} - {formatTime(event.acf.end_time)}
                    </div>

                    {/* Updated User Assignment Hover Menu */}
                    <div
                      className="absolute top-full mt-0 left-1/2 -translate-x-1/2 bg-white shadow-lg rounded-lg p-4 z-50 min-w-[200px] max-w-[300px] hidden group-hover:block"
                    >
                      <div className="text-base font-medium text-gray-700 mb-3 pb-2 border-b">
                        {getEventTitle(event)}
                      </div>
                      <div className="border-gray-200">
                        <div className="max-h-[300px]">
                          {users.map((user) => {
                            const isAssigned = isUserAssignedToEvent(event.id, user);
                            return (
                              <label
                                key={user.id}
                                className="flex items-center space-x-3 py-1.5 hover:bg-gray-50 px-2 rounded cursor-pointer"
                              >
                                <input
                                  type="checkbox"
                                  checked={isAssigned}
                                  onChange={(e) =>
                                    handleUserAssignment(e, event.id, user.id, isAssigned)
                                  }
                                  className="rounded border-gray-300 text-pink-600 focus:ring-pink-500 w-3.5 h-3.5"
                                />
                                <div className="flex items-center space-x-2 flex-1">
                                  <img
                                    src={user.acf.user_image}
                                    alt={user.name}
                                    className="w-6 h-6 rounded-full"
                                  />
                                  <span className="text-xs text-gray-700">{user.name}</span>
                                </div>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}


          {/* Annual Leave display remains the same... */}
          {isBooked && !isDisabled && (
            <div className="mt-auto">
              <p className="text-[10px] leading-tight text-blue-600">Annual Leave:</p>
              <div className="flex gap-2 mt-1">
                {bookedDates[dateStr].map((slug, i) => {
                  const user = users.find(user => user.slug === slug);
                  return user ? (
                    <div
                      key={i}
                      className="relative group w-8 h-8 rounded-full overflow-visible"
                      title={user.name}
                    >
                      <img
                        src={user.acf.user_image}
                        alt={user.name}
                        className="w-full h-full object-cover rounded-full border border-gray-300"
                      />
                      <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 text-xs bg-black text-white px-3 py-1 rounded-md opacity-0 group-hover:opacity-100 transition-opacity z-10 whitespace-nowrap">
                        {user.name}
                      </div>
                    </div>
                  ) : (
                    <p key={i} className="text-[10px] leading-tight text-red-600">
                      Unknown User
                    </p>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return days;
};

  // Navigation functions
  const previousMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
  };

  // Confirmation modal
  const ConfirmationModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-md w-full">
        <h3 className="text-lg font-medium mb-4">Confirm Booking</h3>
        <p>Are you sure you want to book the following dates?</p>
        <div className="mt-2 mb-4">
          {selectedDates.map(date => (
            <div key={date} className="text-sm text-gray-600">
              {new Date(date).toLocaleDateString()}
            </div>
          ))}
        </div>
        <div className="flex justify-end gap-2">
          <button
            onClick={() => setShowConfirmation(false)}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
          >
            Cancel
          </button>
          <button
            onClick={handleBookDates}
            disabled={isSubmitting}
            className="px-4 py-2 bg-blue-600 hover:bg-blue-500 text-white rounded-lg disabled:opacity-50"
          >
            {isSubmitting ? 'Booking...' : 'Confirm'}
          </button>
        </div>
      </div>
    </div>
  );

  const handleRemoveBookedDate = async (date) => {
    try {
      // Call the setBookedDates function to remove the date for the current user
      await setBookedDates(currentUser.id, [], [date]);
  
      //alert('Booked date removed successfully!');
    } catch (err) {
      console.error('Error removing booked date:', err);
      alert('Failed to remove booked date');
    }
  };
  

  const setBookedDates = async (userId, datesToAdd = [], datesToRemove = []) => {
    setIsSubmitting(true);
    refreshData("users");
    try {
      const token = localStorage.getItem('token');
  
      if (!currentUser) {
        throw new Error('User not found');
      }
  
      // Fetch the existing AL requests for the user
      const existingRequests = currentUser?.acf?.al_requests || [];
  
      // Filter out dates to be removed and add new dates
      const updatedRequests = [
        ...existingRequests.filter(req => !datesToRemove.includes(req.al_date)),
        ...datesToAdd.map(date => ({ al_date: date })),
      ];
  
      // Update the user's AL requests in WordPress
      await axios.post(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/users/${userId}`,
        {
          acf: {
            al_requests: updatedRequests,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      refreshData("users");
    } catch (err) {
      console.error('Error updating booked dates:', err);
      alert('Failed to update booked dates');
    } finally {
      setIsSubmitting(false);
    }
  };


    const updateHolidayReset = async (newDate) => {
        setIsSubmitting(true);
        refreshData("users");
        try {
            const token = localStorage.getItem('token');

            if (!currentUser) {
                throw new Error('User not found');
            }

            await axios.post(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/users/${currentUser.id}`,
                {
                    acf: {
                        holiday_reset: newDate,
                    },
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            refreshData("users");
            //alert('Holiday reset date updated successfully');
        } catch (err) {
            console.error('Error updating holiday reset date:', err);
            alert('Failed to update holiday reset date');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDateChange = (e) => {
        const newDate = e.target.value;
        updateHolidayReset(newDate);
    };   
    
    const ClosedDaysModal = () => {
      // Helper function to check if a date is in the future
      const isFutureDate = (dateStr) => {
        if (!dateStr) return false;
        const date = new Date(dateStr);
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset time to start of day for accurate comparison
        return date >= today;
      };
    
      // Filter initial state to only include future dates
      const [localClosedDays, setLocalClosedDays] = useState(
        officeClosed
          .map(({ id, acf: { date, reason } }) => ({
            id,
            date,
            reason,
            deleted: false
          }))
          .filter(day => isFutureDate(day.date))
      );
    
      const [deletedIds, setDeletedIds] = useState([]);
    
      const handleAddClosedDay = () => {
        setLocalClosedDays([
          ...localClosedDays,
          { id: null, date: "", reason: "", deleted: false }
        ]);
      };
    
      const handleDateChange = (index, newDate) => {
        const updatedDays = [...localClosedDays];
        updatedDays[index].date = newDate;
        setLocalClosedDays(updatedDays);
      };
    
      const handleReasonChange = (index, newReason) => {
        const updatedDays = [...localClosedDays];
        updatedDays[index].reason = newReason;
        setLocalClosedDays(updatedDays);
      };
    
      const handleRemoveClosedDay = (index) => {
        const dayToRemove = localClosedDays[index];
        if (dayToRemove.id) {
          setDeletedIds([...deletedIds, dayToRemove.id]);
        }
        setLocalClosedDays(localClosedDays.filter((_, i) => i !== index));
      };
    
      const handleUpdateClosedDays = async (localClosedDays) => {
        setIsSubmitting(true);
        refreshData("office_closed");
        try {
          const token = localStorage.getItem("token");
    
          if (!currentUser) {
            throw new Error("User not found");
          }
    
          // Handle deletions
          for (const idToDelete of deletedIds) {
            console.log("Deleting post:", idToDelete);
            await axios.delete(
              `https://api.crm.sugarprojects.com/wp-json/wp/v2/office_closed/${idToDelete}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            );
          }
    
          // Handle updates and new posts
          for (const closedDay of localClosedDays) {
            const { id, date, reason } = closedDay;
            
            const formattedDate = new Date(date).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
    
            const title = `Office Closed - ${formattedDate}${reason ? ` - ${reason}` : ''}`;
    
            const postData = {
              title: {
                rendered: title,
                raw: title
              },
              status: 'publish',
              acf: {
                date: date,
                reason: reason
              }
            };
    
            if (id) {
              await axios.put(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/office_closed/${id}`,
                postData,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                }
              );
            } else {
              await axios.post(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/office_closed`,
                postData,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                }
              );
            }
          }
    
          refreshData("office_closed");
          setShowClosedDays(false);
        } catch (err) {
          console.error("Error updating office closed days:", err);
          alert("Failed to update office closed days.");
        } finally {
          setIsSubmitting(false);
        }
      };
    
      return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full">
            <h3 className="text-lg font-medium mb-4">Upcoming Office Closures:</h3>
            <div className="space-y-4">
              {localClosedDays.map((day, index) => (
                <div key={index} className="flex gap-4 items-center">
                  <input
                    type="date"
                    value={day.date}
                    onChange={(e) => handleDateChange(index, e.target.value)}
                    className="focus:ring-green-600 focus:border-green-600 rounded p-2 flex-1"
                  />
                  <input
                    type="text"
                    value={day.reason}
                    onChange={(e) => handleReasonChange(index, e.target.value)}
                    placeholder="Reason"
                    className="focus:ring-green-600 focus:border-green-600 rounded p-2 flex-1"
                  />
                  <button
                    onClick={() => handleRemoveClosedDay(index)}
                    className="text-green-500 hover:text-green-700"
                  >
                    -
                  </button>
                </div>
              ))}
              <button
                onClick={handleAddClosedDay}
                className="text-green-600 hover:text-green-500"
              >
                + Add New
              </button>
            </div>
    
            <div className="flex justify-end gap-2 mt-6">
              <button
                onClick={() => setShowClosedDays(false)}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={() => handleUpdateClosedDays(localClosedDays)}
                disabled={isSubmitting}
                className="px-4 py-2 bg-green-600 hover:bg-green-500 text-white rounded-lg disabled:opacity-50"
              >
                {isSubmitting ? "Updating..." : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      );
    };

    const EventModal = () => {
      const [eventsData, setEventsData] = useState([]);
      const [isSubmitting, setIsSubmitting] = useState(false);
    
      useEffect(() => {
        const newEvents = selectedDates.map(date => ({
          date,
          start_time: "",
          end_time: "",
          title: ""
        }));
        setEventsData(newEvents);
      }, [selectedDates]);
    
      const handleInputChange = (index, field, value) => {
        const updatedEvents = eventsData.map((event, i) => 
          i === index ? { ...event, [field]: value } : event
        );
        setEventsData(updatedEvents);
      };
    
      const handleRemoveDate = (index) => {
        // Remove from eventsData
        const updatedEvents = eventsData.filter((_, i) => i !== index);
        setEventsData(updatedEvents);
        
        // Remove from selectedDates
        const dateToRemove = eventsData[index].date;
        const updatedSelectedDates = selectedDates.filter(date => date !== dateToRemove);
        setSelectedDates(updatedSelectedDates);
        
        // If no events left, close the modal
        if (updatedEvents.length === 0) {
          setShowEventModal(false);
        }
      };
    
      const handleUpdateEvents = async () => {
        setIsSubmitting(true);
        try {
          const token = localStorage.getItem("token");
      
          // Handle updates and new posts
          for (const event of eventsData) {
            // Create the event and get its ID from the response
            const eventResponse = await axios.post(
              `https://api.crm.sugarprojects.com/wp-json/wp/v2/events`,
              {
                title: event.title,
                status: 'publish',
                acf: {
                  date: event.date,
                  start_time: event.start_time,
                  end_time: event.end_time
                }
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                }
              }
            );
      
            const newEventId = eventResponse.data.id;
      
            // Update each user's event list
            for (const user of users) {
              // Get current user events or initialize empty array
              const currentUserEvents = user.acf?.user_events || [];
              
              // Add new event to the list, matching the structure of your working example
              const updatedUserEvents = [
                ...currentUserEvents,
                { event_id: newEventId.toString() }  // Convert to string to ensure consistent type
              ];
      
              // Update user's ACF field using the same structure as your working example
              await axios.post(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/users/${user.id}`,
                {
                  acf: {
                    user_events: updatedUserEvents
                  }
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  }
                }
              );
            }
          }
      
          refreshData("events");
          refreshData("users");
          setShowEventModal(false);
        } catch (err) {
          console.error("Error updating events:", err);
          alert("Failed to update events.");
        } finally {
          setIsSubmitting(false);
        }
      };
    
      return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg">
            <h3 className="text-lg font-medium mb-4">Mark Events:</h3>
            {eventsData.map((eventData, index) => (
              <div key={`${eventData.date}-${index}`} className="flex my-2 gap-4 items-center">
                <div>
                  {new Date(eventData.date).toLocaleDateString('en-GB')}
                </div>
                <input
                  type="time"
                  value={eventData.start_time}
                  onChange={(e) => handleInputChange(index, "start_time", e.target.value)}
                  className="focus:ring-pink-600 focus:border-pink-600 rounded p-2"
                />
                <input
                  type="time"
                  value={eventData.end_time}
                  onChange={(e) => handleInputChange(index, "end_time", e.target.value)}
                  className="focus:ring-pink-600 focus:border-pink-600 rounded p-2"
                />
                <input
                  type="text"
                  value={eventData.title}
                  onChange={(e) => handleInputChange(index, "title", e.target.value)}
                  placeholder="Event"
                  className="focus:ring-pink-600 focus:border-pink-600 rounded p-2"
                />
                <button
                  onClick={() => handleRemoveDate(index)}
                  className="text-pink-500 hover:text-pink-700"
                >
                  -
                </button>
              </div>
            ))}
            <div className="flex justify-end gap-2 mt-6">
              <button
                onClick={() => setShowEventModal(false)}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={handleUpdateEvents}
                disabled={isSubmitting}
                className="px-4 py-2 bg-pink-600 hover:bg-pink-500 text-white rounded-lg disabled:opacity-50"
              >
                {isSubmitting ? "Updating..." : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      );
    };

    const DeleteEventModal = () => {
        // Handle event deletion
        const handleDeleteEvent = async () => {
          setShowDeleteEventModal(false);
          refreshData("events");
          try {
            const token = localStorage.getItem("token");
            
            if (!token) {
              throw new Error("Authentication token not found");
            }

            await axios.delete(
              `https://api.crm.sugarprojects.com/wp-json/wp/v2/events/${eventId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                }
              }
            );

            refreshData("events"); // Refresh events after deletion
          } catch (err) {
            console.error("Error deleting event:", err);
            alert("Failed to delete event.");
          }
        };

      return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg">
            <h3 className="text-lg font-medium mb-4">Are you sure you want to delete this event?</h3>
            <div className="flex justify-end gap-2 mt-6">
              <button
                onClick={() => setShowDeleteEventModal(false)}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
              >
                No
              </button>
              <button
                onClick={() => handleDeleteEvent()}
                disabled={isSubmitting}
                className="px-4 py-2 bg-red-600 hover:bg-red-500 text-white rounded-lg disabled:opacity-50"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      );
    };
    
    

  return (
    <div className="w-full flex justify-between p-8">
      <div className='w-[80%]'>
        <div className="flex items-center justify-between mb-6">
          <div className=''>
            <h2 className="text-xl font-bold">Team Calendar</h2>
            <button onClick={() => setShowClosedDays(true)} className='text-green-600 hover:text-green-500'>
              Edit Closed Days
            </button>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={previousMonth}
              className="p-2 hover:bg-pink-50 rounded-full transition-colors"
            >
              ←
            </button>
            <span className="font-medium">
              {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
            </span>
            <button
              onClick={nextMonth}
              className="p-2 hover:bg-pink-50 rounded-full transition-colors"
            >
              →
            </button>
          </div>
        </div>

        <div className="grid grid-cols-7 gap-px">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <div key={day} className="h-8 flex items-center justify-center font-medium">
              {day}
            </div>
          ))}
          {generateCalendarDays()}
        </div>
        </div>

        <div className='w-[18%]'>
            <div>
                <label>Holiday Reset Date:</label>
                <input
                    type="date"
                    id="holiday-reset"
                    name="holiday-reset"
                    defaultValue={currentUser?.acf?.holiday_reset}
                    className="border rounded p-2 mt-2 w-full"
                    onChange={handleDateChange}
                    disabled={isSubmitting}
                />
                <p className="mt-2">Remaining Days: {remainingDays}</p>
            </div>
            {Object.keys(bookedDates).length > 0 && (
              <div className="mt-4">
                <div className="flex items-center justify-between">
                  <h3 className="font-medium mb-2">Booked Annual Leave:</h3>
                  <div
                    onClick={toggleDropdown}
                    className="p-1 hover:bg-gray-100 rounded-full transition-colors"
                  >
                    {isOpen ? (
                      <ChevronUp className="w-5 h-5" />
                    ) : (
                      <ChevronDown className="w-5 h-5" />
                    )}
                  </div>
                </div>
                {isOpen && (
                  <div className="flex flex-wrap gap-2">
                    {Object.entries(bookedDates)
                      .filter(([date, users]) =>
                        users.some(user => user === currentUser?.slug?.toLowerCase())
                      )
                      .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
                      .map(([date, users]) => (
                        <div
                          key={date}
                          className={`px-3 py-1 rounded-full flex items-center gap-2 ${
                            selectedDates.includes(date) ? 'bg-pink-50 text-pink-600' : 'bg-gray-50 text-gray-600'
                          }`}
                        >
                          <span>{new Date(date).toLocaleDateString()}</span>
                          <button
                            onClick={() => handleRemoveBookedDate(date)}
                            className={`hover:${selectedDates.includes(date) ? 'text-pink-700' : 'text-gray-700'}`}
                          >
                            <X size={16} />
                          </button>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            )}


        
        {selectedDates.length > 0 && (
            <div className="mt-4">
                <h3 className="font-medium mb-2">Selected Dates:</h3>
                <div className="flex flex-wrap gap-2">
                    {selectedDates.map(date => (
                        <div
                            key={date}
                            className="bg-pink-50 text-pink-600 px-3 py-1 rounded-full flex items-center gap-2"
                        >
                            <span>{new Date(date).toLocaleDateString('en-GB')}</span>
                            <button
                                onClick={() => setSelectedDates(selectedDates.filter(d => d !== date))}
                                className="hover:text-pink-700"
                            >
                                <X size={16} />
                            </button>
                        </div>
                    ))}
                </div>

                {/* Show "Book Annual Leave" button only if there's a date not in `al_requests` */}
                {selectedDates.some(date => 
                    !currentUser.acf.al_requests.some(request => request.al_date === date)
                ) && (
                  <button
                  onClick={() => {
                      // Remove `al_requests` dates from `selectedDates`
                      const filteredDates = selectedDates.filter(
                          date => !currentUser.acf.al_requests.some(request => request.al_date === date)
                      );
                      setSelectedDates(filteredDates);
  
                      // Show confirmation dialog
                      setShowConfirmation(true);
                  }}
                  className="mt-4 bg-blue-600 hover:bg-blue-500 text-white px-4 py-2 rounded-lg transition-colors"
              >
                  Book Annual Leave
              </button>
                )}

                <button
                    onClick={() => setShowEventModal(true)}
                    className="mt-4 bg-pink-600 hover:bg-pink-500 text-white px-4 py-2 rounded-lg transition-colors"
                >
                    Mark Event
                </button>
            </div>
        )}

      </div>

      {showConfirmation && <ConfirmationModal />}
      {showClosedDays && <ClosedDaysModal />}
      {showEventModal && <EventModal />}
      {showDeleteEventModal && <DeleteEventModal />}
    </div>
  );
};

export default AnnualLeaveCalendar;