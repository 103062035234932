import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ setIsAuthenticated }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [step, setStep] = useState(1);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleOtpVerification = async (e) => {
    e.preventDefault();
    setError(null);
  
    try {
      const response = await axios.post(
        'https://api.crm.sugarprojects.com/wp-json/custom/v1/verify-otp',
        { username, otp, password },
        { headers: { 'Content-Type': 'application/json' } }
      );
  
      const { token, deviceToken } = response.data;
  
      localStorage.setItem('token', token);
      localStorage.setItem('deviceToken', deviceToken);
      localStorage.setItem('username', username);
  
      setIsAuthenticated(true);
      navigate('/clients');
    } catch (err) {
      console.error('Error verifying OTP:', err);
      setError('Invalid OTP or server error');
    }
  };
  
  
  // Modify handleLogin to include deviceToken if available
  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
  
    const deviceToken = localStorage.getItem('deviceToken');
  
    try {
      const response = await axios.post(
        'https://api.crm.sugarprojects.com/wp-json/custom/v1/send-otp',
        { username, password, deviceToken }
      );
  
      console.log('Login response:', response.data);
  
      if (response?.data?.status === 'otp_sent') {
        setStep(2); // Proceed to OTP step
      } else if (response?.data?.status === 'device_recognized') {
        const { token } = response.data;
        localStorage.setItem('token', token);
        localStorage.setItem('username', username);
        setIsAuthenticated(true);
        navigate('/clients');
      } else {
        setError('Unexpected response from server');
      }
    } catch (err) {
      console.error('Error logging in:', err);
      setError('Invalid username or password');
    }
  };
  
  
  

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-pink-700 p-8 rounded-lg shadow-md w-full max-w-md">
        <div className="flex justify-center mb-6">
          <img
            src="https://sugarprojects.s3.eu-west-1.amazonaws.com/wp-content/uploads/2024/02/27161206/logo-one-colour.svg"
            alt="Company Logo"
            className="h-16"
          />
        </div>
        {step === 1 && (
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label className="block text-white font-semibold mb-2">Username</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full px-4 py-2 border rounded-md bg-gray-100"
                placeholder="Enter your username"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-white font-semibold mb-2">Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border rounded-md bg-gray-100"
                placeholder="Enter your password"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-white text-black py-2 rounded-md hover:bg-pink-100 transition duration-200"
            >
              Login
            </button>
          </form>
        )}
        {step === 2 && (
          <form onSubmit={handleOtpVerification}>
            <div className="mb-4">
              <label className="block text-white font-semibold mb-2">OTP</label>
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="w-full px-4 py-2 border rounded-md bg-gray-100"
                placeholder="Enter OTP sent to your phone"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-white text-black py-2 rounded-md hover:bg-pink-100 transition duration-200"
            >
              Verify OTP
            </button>
          </form>
        )}
        {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
      </div>
    </div>
  );
};

export default Login;
